import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

interface Chapter {
  title: string;
  summary: string;
}

interface ChapterSelectStoryProps {
  chapters: Chapter[] | null;
  onChapterSelect: (index: number) => void;
  onBack: () => void;
  onNext: () => void;
}

const ChapterSelectStory: React.FC<ChapterSelectStoryProps> = ({
  chapters,
  onChapterSelect,
  onBack,
  onNext,
}) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select a Chapter to Generate
      </Typography>
      <List>
        {chapters?.map((chapter, index) => (
          <ListItem
            key={index}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              mb: 2,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                cursor: "pointer",
              },
            }}
            onClick={() => onChapterSelect(index)}
          >
            <ListItemText
              primary={
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {`Chapter ${index + 1}: ${chapter.title}`}
                </Typography>
              }
              secondary={chapter.summary}
            />
          </ListItem>
        ))}
      </List>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Button onClick={onBack}>Back</Button>
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ChapterSelectStory;
