// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Add global styles if needed
import App from './App';

window.addEventListener('error', (event) => {
  if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
    event.stopImmediatePropagation();
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
