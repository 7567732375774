import { Box, Button, Divider, Link, Modal, Typography } from "@mui/material";
import React from "react";

interface PrivacyPolicyProps {
  open?: boolean;
  onClose?: () => void;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ open, onClose }) => {
  const formatEmail = (email: string) => (
    <Link href={`mailto:${email}`} color="primary" underline="hover">
      {email}
    </Link>
  );

  const content = (
    <>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontWeight: "bold", color: "primary.main" }}
      >
        Mosaic App Privacy Policy
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph>
        Last updated: August 13, 2024
      </Typography>

      <Divider sx={{ my: 2 }} />

      {[
        {
          title: "1. Introduction",
          content: `Welcome to Mosaic ("we," "our," or "us"). We are committed to protecting your privacy while providing you with a powerful tool for relationship insights. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Mosaic application and related services (collectively, the "Service").`,
        },
        {
          title: "2. Information We Collect",
          content: [
            {
              subtitle: "2.1 User-Provided Information",
              subcontent: `When you use our Service, you may provide us with:
• Chat logs and conversation data
• Personal information such as names, email addresses, and demographic information
• Any other information you choose to provide`,
            },
            {
              subtitle: "2.2 Automatically Collected Information",
              subcontent: `We may automatically collect certain information about your device and usage of the Service, including:
• Device information (e.g., IP address, browser type, operating system)
• Usage data (e.g., features used, time spent on the Service)
• Analytics data derived from your conversations and interactions`,
            },
          ],
        },
        {
          title: "3. Use of Information",
          content: `We may use the information we collect for various purposes, including but not limited to:
• Providing and improving our Service
• Analyzing conversation patterns and generating insights
• Developing new features and services
• Communicating with you about our Service
• Research and analytics
• Complying with legal obligations`,
        },
        {
          title: "4. Data Storage and Security",
          content: `We store your data, including conversation logs, on Amazon S3 and other secure cloud services. While we implement reasonable security measures, no method of transmission or storage is 100% secure. You acknowledge that you provide your information at your own risk.`,
        },
        {
          title: "5. Data Sharing and Disclosure",
          content: `We may share your information in the following circumstances:
• With service providers and business partners who assist in operating our Service
• For research and analytical purposes (in anonymized or aggregated form)
• In connection with a business transaction (e.g., merger, acquisition, or sale of assets)
• To comply with legal obligations or protect our rights`,
        },
        {
          title: "6. Your Choices",
          content: (
            <>
              You may have the following rights regarding your information:
              <ul>
                <li>Access and update your personal information</li>
                <li>
                  Request deletion of your data (subject to our legal
                  obligations and legitimate business interests)
                </li>
                <li>Opt-out of certain data uses for marketing purposes</li>
              </ul>
              To exercise these rights, please contact us at{" "}
              {formatEmail("mosaic.app.legal@gmail.com")}.
            </>
          ),
        },
        {
          title: "7. Changes to This Privacy Policy",
          content: `We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.`,
        },
        {
          title: "8. Contact Us",
          content: (
            <>
              If you have any questions about this Privacy Policy, please
              contact us at: {formatEmail("mosaic.app.legal@gmail.com")}.
              <br />
              <br />
              By using our Service, you agree to the collection and use of
              information in accordance with this Privacy Policy.
            </>
          ),
        },
      ].map((section, index) => (
        <Box key={index} sx={{ mb: 3 }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              textAlign: "left",
            }}
          >
            {section.title}
          </Typography>
          {Array.isArray(section.content) ? (
            section.content.map((subsection, subIndex) => (
              <Box key={subIndex} sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    color: "text.secondary",
                    textAlign: "left",
                  }}
                >
                  {subsection.subtitle}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ whiteSpace: "pre-line" }}
                >
                  {subsection.subcontent}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography
              variant="body1"
              paragraph
              sx={{ whiteSpace: "pre-line" }}
            >
              {section.content}
            </Typography>
          )}
          {index < 7 && <Divider sx={{ my: 2 }} />}
        </Box>
      ))}
    </>
  );

  if (open !== undefined && onClose) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="privacy-policy-modal"
        aria-describedby="privacy-policy-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          {content}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-start" }}>
            <Button onClick={onClose} variant="contained" color="primary">
              I Understand and Accept
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  return <Box sx={{ p: 4 }}>{content}</Box>;
};

export default PrivacyPolicy;
