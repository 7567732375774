import { Paper, PaperProps, styled } from '@mui/material'
import React, { ReactNode } from 'react'

const GradientPaper = styled(Paper)(({ theme }) => ({
  background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
  backgroundSize: '400% 400%',
  animation: 'gradientShift 15s ease infinite',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10],
  },
  '@keyframes gradientShift': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
}))

interface GradientPaperProps extends PaperProps {
  children: ReactNode
}

const GradientPaperComponent: React.FC<GradientPaperProps> = ({
  children,
  ...props
}) => {
  return <GradientPaper {...props}>{children}</GradientPaper>
}

export default GradientPaperComponent
