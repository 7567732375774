import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { ChatUser } from '../../../utils/types'

type UserNameAndIdentityDialogProps = {
  open: boolean
  users: ChatUser[]
  onClose: () => void
  onNameChange: (updatedUsers: ChatUser[]) => void
}

const UserNameAndIdentityDialog: React.FC<UserNameAndIdentityDialogProps> = ({
  open,
  onClose,
  users,
  onNameChange,
}) => {
  const [editedUsers, setEditedUsers] = useState<ChatUser[]>(users)
  const [selectedIdentity, setSelectedIdentity] = useState<string>('')

  // Auto-select "me" if it exists
  useEffect(() => {
    if (open && users) {
      const currentMe = users.find((u) => u.isMe)
      if (currentMe) {
        setSelectedIdentity(currentMe.username)
      }
    }
  }, [open, users])

  const handleSave = () => {
    const updatedUsers = editedUsers.map((user) => ({
      ...user,
      isMe: user.username === selectedIdentity,
      edited: true,
    }))
    onNameChange(updatedUsers)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Names & Select Your Identity</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Edit display names and select which participant is you.
        </Typography>

        {/* Name editing section */}
        <List>
          {editedUsers?.map((user) => (
            <ListItem
              key={user.username}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 1,
              }}
            >
              <TextField
                label="Username"
                value={user.username}
                disabled
                size="small"
                sx={{ flexBasis: '30%' }}
              />
              <TextField
                label="Display Name"
                value={user.name}
                onChange={(e) =>
                  setEditedUsers((prev) =>
                    prev.map((u) =>
                      u.username === user.username
                        ? { ...u, name: e.target.value }
                        : u
                    )
                  )
                }
                size="small"
                sx={{ flexBasis: '40%' }}
              />
            </ListItem>
          ))}
        </List>

        {/* Identity selection section */}
        <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>
          Who are you in this conversation?
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {editedUsers?.map((user) => (
            <Button
              key={user.username}
              variant={
                selectedIdentity === user.username ? 'contained' : 'outlined'
              }
              onClick={() => setSelectedIdentity(user.username)}
              sx={{
                textTransform: 'none',
                flexGrow: 1,
                minWidth: 'fit-content',
              }}
            >
              {user.name}
            </Button>
          ))}
          <Button
            variant={selectedIdentity === '' ? 'contained' : 'outlined'}
            onClick={() => setSelectedIdentity('')}
            sx={{
              textTransform: 'none',
              flexGrow: 1,
              minWidth: 'fit-content',
            }}
          >
            Neither
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserNameAndIdentityDialog
