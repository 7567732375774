import { getWeekStart } from '../components/UserDashboard/components/dateUtils'
import { ChatMessage } from '../utils/types'

export type ChartData = {
  weekStart: string
  numMessages: {
    [key: string]: number
  }
  numWords: {
    [key: string]: number
  }
}

export type EngagementData = {
  chartData: ChartData[]
  persons: string[]
}

export const processEngagementData = async (
  parsedData: ChatMessage[]
): Promise<EngagementData> => {
  const weeklyData: {
    [key: string]: {
      numMessages: { [key: string]: number }
      numWords: { [key: string]: number }
    }
  } = {}
  const personSet: Set<string> = new Set()

  parsedData.forEach((message) => {
    const weekStart = getWeekStart(message.date)
    const wordCount = message.message.split(/\s+/).length
    personSet.add(message.user)
    if (!weeklyData[weekStart]) {
      weeklyData[weekStart] = {
        numMessages: {},
        numWords: {},
      }
    }
    weeklyData[weekStart].numMessages[message.user] =
      (weeklyData[weekStart].numMessages[message.user] || 0) + 1
    weeklyData[weekStart].numWords[message.user] =
      (weeklyData[weekStart].numWords[message.user] || 0) + wordCount
  })

  const sortedWeeks = Object.keys(weeklyData).sort()
  const filledData = sortedWeeks.map((weekStart) => {
    const weekData: ChartData = {
      weekStart,
      numMessages: {},
      numWords: {},
    }
    personSet.forEach((person) => {
      weekData.numMessages[person] =
        weeklyData[weekStart].numMessages[person] || 0
      weekData.numWords[person] = weeklyData[weekStart].numWords[person] || 0
    })
    return weekData
  })

  const result = { chartData: filledData, persons: Array.from(personSet) }
  return result
}
