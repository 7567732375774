import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import PrivacyPolicyModal from "../Documents/PrivacyPolicy";
import TermsOfServiceModal from "../Documents/TermsOfService";

const Privacy: React.FC = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const handleOpenPrivacyModal = () => setIsPrivacyModalOpen(true);
  const handleClosePrivacyModal = () => setIsPrivacyModalOpen(false);
  const handleOpenTermsModal = () => setIsTermsModalOpen(true);
  const handleCloseTermsModal = () => setIsTermsModalOpen(false);

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f9f9f9", textAlign: "center" }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: "bold",
          color: "#333",
          marginBottom: 2,
        }}
      >
        Privacy and Terms
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: "800px",
          marginX: "auto",
          lineHeight: 1.6,
          color: "#555",
        }}
      >
        We at Mosaic take your privacy incredibly seriously. We use one of the
        most secure cloud platforms in the world to ensure data integrity. Read
        up more on how your data is used in our{" "}
        <Link
          component="button"
          variant="body1"
          onClick={handleOpenPrivacyModal}
          sx={{
            color: "primary.main",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          privacy policy
        </Link>{" "}
        and{" "}
        <Link
          component="button"
          variant="body1"
          onClick={handleOpenTermsModal}
          sx={{
            color: "primary.main",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          terms of service
        </Link>
        .
      </Typography>

      <PrivacyPolicyModal
        open={isPrivacyModalOpen}
        onClose={handleClosePrivacyModal}
      />
      <TermsOfServiceModal
        open={isTermsModalOpen}
        onClose={handleCloseTermsModal}
      />
    </Box>
  );
};

export default Privacy;
