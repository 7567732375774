import { Box, CssBaseline } from "@mui/material";
import { styled } from "@mui/material/styles";
import { fetchAuthSession } from "aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneralInfoContext } from "../GeneralInfoContext";

import Footer from "./LPC/Footer";
import Privacy from "./LPC/Privacy";
import RedesignedTopSection from "./LPC/RedesignedTopSection";
import WhatIsMosaic from "./LPC/WhatIsMosaic";
import WelcomePopup from "./Popups/WelcomePopup";

const PageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  width: "100%",
  overflow: "hidden",
  position: "relative",
});

const SectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  backgroundColor: "white",
  padding: "50px 20px",
});

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { setToken, setHash } = useGeneralInfoContext();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [welcomePopupOpen, setWelcomePopupOpen] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const session = await fetchAuthSession();
        setIsAuthenticated(!!session.tokens);
      } catch (error) {
        console.error("Error checking auth state:", error);
        setIsAuthenticated(false);
      }
    };
    checkAuth();

    // Check for hash parameter in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const hashParam = urlParams.get("hash");
    const tokenParam = urlParams.get("token");
    if (hashParam && tokenParam) {
      setHash(hashParam);
      setToken(tokenParam);
      navigate("/main");
    }

    // Check for welcome popup flag
    if (localStorage.getItem("showWelcomePopup") === "true") {
      setWelcomePopupOpen(true);
      localStorage.removeItem("showWelcomePopup");
    }
  }, [navigate, setHash, setToken]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/user-dashboard");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
    >
      <CssBaseline />
      <PageContainer>
        <RedesignedTopSection />
        <SectionContainer>
          <WhatIsMosaic />
        </SectionContainer>
        <SectionContainer>
          <Privacy />
        </SectionContainer>
        <Footer />
      </PageContainer>
      <WelcomePopup
        open={welcomePopupOpen}
        onClose={() => setWelcomePopupOpen(false)}
      />
    </Box>
  );
};

export default LandingPage;
