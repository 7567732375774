import { Box, LinearProgress } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import JSZip from 'jszip'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { getToken, hashFile, signFile } from '../utils/hashAuthentication'
import { parseIMessage } from '../utils/imessage'
import { deleteFileWithPresignedUrl, requestFile } from '../utils/s3cache'
import { uploadToS3 } from '../utils/s3Storage'
import { parse } from '../utils/universal'
import { ZipFileDialog } from './ZipFileDialog'

export interface ZipFile {
  name: string
  preview: string
  date: Date
}

export interface WithFileUploadAndParseProps {
  onFileUpload: (file: File) => void
  isLoading: boolean
  error: string | null
  zipFiles: ZipFile[]
  isZipDialogOpen: boolean
  handleZipFileSelection: (fileName: string) => void
  setIsZipDialogOpen: (isOpen: boolean) => void
  fileInputRef: React.RefObject<HTMLInputElement>
  uploadProgress: number
}

export const withFileUploadAndParse = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithFileUploadAndParseProps>
) => {
  return (props: P & { onUploadSuccess?: (chatId: string) => void }) => {
    const navigate = useNavigate()
    const [error, setError] = useState<string | null>(null)
    const [zipFiles, setZipFiles] = useState<ZipFile[]>([])
    const [isZipDialogOpen, setIsZipDialogOpen] = useState(false)
    const [zipContent, setZipContent] = useState<JSZip | null>(null)
    const [uploadProgress, setUploadProgress] = useState<number>(0)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const { setParsedData, setToken, setHash, setFile, token, hash } =
      useGeneralInfoContext()
    const fileInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (token && hash) {
        navigate('/main')
      }
    }, [token, hash, navigate])

    const uploadProcess = async (file: File) => {
      const { tokens } = await fetchAuthSession()
      const hash = await hashFile(file)
      setHash(hash)
      setFile(file)
      await uploadToS3(file, hash, false, (progress) => {
        console.log('upload progress', progress)
        setUploadProgress(Math.max(progress, 5))
      })
      const refreshToken = () => getToken(hash, file, 3600)
      const token = await refreshToken()
      setToken(token)
      if (tokens) {
        console.log('signing file', hash)
        await signFile(hash, file)
        const desiredUrl = `chat/${hash}/image.png`
        const presignedUrl = await requestFile(
          desiredUrl,
          hash,
          token,
          refreshToken,
          true
        )
        console.log('presignedUrl for image', presignedUrl)
      }
      if (props.onUploadSuccess) {
        props.onUploadSuccess(hash)
      }
    }

    const extractTextFromZip = async (file: File): Promise<string> => {
      const zip = new JSZip()
      const contents = await zip.loadAsync(file)
      setZipContent(contents)

      const txtFiles = Object.keys(contents.files).filter((name) =>
        name.endsWith('.txt')
      )

      if (txtFiles.length === 0) {
        throw new Error('No text file found in the zip archive')
      }

      let filesPreviews = await Promise.all(
        txtFiles.map(async (fileName) => {
          const textContent = await contents.file(fileName)!.async('string')
          if (!textContent || textContent.length < 3000) {
            return null
          }
          const isImessage = textContent.includes(': not_me:')
          let preview = ''
          let date = new Date()
          if (isImessage) {
            preview = textContent
              .split('\n')
              .filter((line) => line && line.trim() !== '')
              .map((line) => line.split(': ').slice(1).join(': '))
              .filter((line) => line && line.trim() !== '')
              .slice(-3)
              .join('\n')
            date = new Date(fileName.split('_')[2].split('.')[0])
          } else {
            preview = textContent
              .split('\n')
              .filter((line) => line && line.trim() !== '')
              .slice(-3)
              .join('\n')
          }

          const result = {
            name: fileName,
            preview,
            length: textContent.length,
            date,
          }
          console.log('result', result, textContent)
          return result
        })
      )
      filesPreviews = filesPreviews.filter(Boolean)
      filesPreviews.sort(
        (a, b) => (b?.date.getTime() || 0) - (a?.date.getTime() || 0)
      )
      setZipFiles(
        filesPreviews as {
          name: string
          preview: string
          length: number
          date: Date
        }[]
      )
      console.log('zipFiles', filesPreviews)
      setIsZipDialogOpen(true)

      return ''
    }

    const extractTextFromDb = async (file: File): Promise<string> => {
      const hash = await hashFile(file)
      const fileName = `imessage/${hash}.db`
      await uploadToS3(file, fileName, false, (progress) => {
        console.log('upload progress', progress)
        setUploadProgress(Math.max(progress, 5))
      })
      const response = await parseIMessage(fileName, file)
      const rawFile = await fetch(response.presigned_url).then((r) => r.blob())
      await deleteFileWithPresignedUrl(response.presigned_delete_url)
      const newFile = new File([rawFile], 'chat.zip', {
        type: 'application/zip',
      })
      await extractTextFromZip(newFile)
      return ''
    }

    const processFileUpload = async (file: File) => {
      console.log('processing upload', file)
      setIsProcessing(true)
      setError(null)
      setUploadProgress(5)
      try {
        if (file.name === 'SampleChat.txt') {
          const text = await file.text()
          const parsedData = await parse(text)
          setParsedData(parsedData)
          await uploadProcess(file)
        } else if (file.name.endsWith('.zip')) {
          await extractTextFromZip(file)
        } else if (file.name.endsWith('.db')) {
          await extractTextFromDb(file)
        } else {
          const text = await file.text()
          const parsedData = await parse(text)
          setParsedData(parsedData)
          await uploadProcess(file)
        }
      } catch (error) {
        console.error('Error processing file:', error)
        setError('Error processing file. Please try again.')
      } finally {
        setIsProcessing(false)
        setUploadProgress(0)
      }
    }

    const handleZipFileSelection = async (fileName: string) => {
      setIsZipDialogOpen(false)
      setIsProcessing(true)
      setError(null)
      setUploadProgress(0)

      try {
        if (!zipContent) {
          throw new Error(
            'Zip content not found. Please try uploading the file again.'
          )
        }
        const selectedFile = zipContent.file(fileName)
        if (!selectedFile) {
          throw new Error('Selected file not found in the zip archive.')
        }

        const textContent = await selectedFile.async('string')
        const parsedData = await parse(textContent)
        setParsedData(parsedData)

        const dummyFile = new File([textContent], fileName, {
          type: 'text/plain',
        })
        await uploadProcess(dummyFile)
      } catch (error) {
        console.error('Error processing zip file:', error)
        setError(
          error instanceof Error ? error.message : 'An unknown error occurred'
        )
      } finally {
        setIsProcessing(false)
        setUploadProgress(0)
      }
    }

    const onFileUpload = (file: File) => {
      console.log('onFileUpload', file)
      processFileUpload(file)
    }

    return (
      <>
        <WrappedComponent
          {...props}
          onFileUpload={onFileUpload}
          isLoading={isProcessing}
          error={error}
          zipFiles={zipFiles}
          isZipDialogOpen={isZipDialogOpen}
          handleZipFileSelection={handleZipFileSelection}
          setIsZipDialogOpen={setIsZipDialogOpen}
          fileInputRef={fileInputRef}
          uploadProgress={uploadProgress}
          isProcessing={isProcessing}
        />
        {isProcessing && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
        )}
        {ZipFileDialog({
          isOpen: isZipDialogOpen,
          onClose: () => {
            console.log('onClose')
            setIsZipDialogOpen(false)
            setZipFiles([])
            setZipContent(null)
            setIsProcessing(false)
            fileInputRef.current!.value = ''
          },
          zipFiles,
          onFileSelect: handleZipFileSelection,
        })}
      </>
    )
  }
}
