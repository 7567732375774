import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
interface VideoContainerProps {
  selectedFileType: string | null
}

const VideoContainer: React.FC<VideoContainerProps> = ({
  selectedFileType,
}) => {
  const [videoKey, setVideoKey] = useState(0)

  const isLaptop =
    selectedFileType === 'WhatsApp (Laptop)' ||
    selectedFileType === 'iMessage (Mac)' ||
    selectedFileType === 'Telegram (Laptop)' ||
    selectedFileType === null

  // S3 URLs for videos
  const videoUrls: Record<string, string> = {
    'iMessage (Mac)':
      'https://relanalysis-public.s3.amazonaws.com/imessage_upload.mp4',
    'WhatsApp (Laptop)':
      'https://relanalysis-public.s3.amazonaws.com/whatsapp_upload_laptop.mp4',
    'WhatsApp (Mobile)':
      'https://relanalysis-public.s3.amazonaws.com/whatsapp_upload_mobile.mp4',
    Android:
      'https://relanalysis-public.s3.amazonaws.com/sms_android_placeholder.mp4',
    Instagram:
      'https://relanalysis-public.s3.amazonaws.com/instagram_upload.mp4',
    Facebook: 'https://relanalysis-public.s3.amazonaws.com/facebook_upload.mp4',
    'Telegram (Laptop)':
      'https://relanalysis-public.s3.amazonaws.com/telegram_upload.mp4',
  }

  // Default video URL
  const defaultVideoUrl =
    'https://relanalysis-public.s3.amazonaws.com/default_video.mp4'

  // Determine which video URL to use based on the selectedFileType
  const videoSource = selectedFileType
    ? videoUrls[selectedFileType] || defaultVideoUrl
    : defaultVideoUrl

  useEffect(() => {
    console.log('Component mounted or updated')
    console.log('Selected File Type:', selectedFileType)
    console.log('Video Source URL:', videoSource)
    console.log('Is Laptop:', isLaptop)

    // Force video reload when source changes
    setVideoKey((prevKey) => prevKey + 1)
  }, [selectedFileType, videoSource, isLaptop])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          aspectRatio: isLaptop ? '16 / 10' : '9 / 19.5',
          position: 'relative',
          zIndex: 11,
          paddingBottom: isLaptop ? '2%' : '0',
        }}
      >
        {/* Device Frame */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            background: '#000',
            borderRadius: isLaptop ? '24px' : '48px',
            padding: isLaptop ? '1.5%' : '3%',
            // boxShadow: '0 15px 40px rgba(0,0,0,0.3)',
            position: 'relative',
            // overflow: 'hidden',
            zIndex: 12,
          }}
        >
          {/* Video Content */}
          <Box
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: isLaptop ? '12px' : '42px',
              overflow: 'hidden',
            }}
          >
            <video
              key={videoKey}
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              onLoadStart={() =>
                console.log('Video load started:', videoSource)
              }
              onLoadedData={() =>
                console.log('Video loaded successfully:', videoSource)
              }
              onError={(e) =>
                console.error('Error loading video:', videoSource, e)
              }
            >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>

        {/* Hinge Area */}

        {/* isLaptop && (
          <Box
            sx={{
              width: '100%',
              height: '4%',
              background: '#444',
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
              position: 'relative',
              zIndex: 12,
            }}
          />
        ) */ }

        {/* isLaptop && (
          // Laptop Body
          <Box
            sx={{
              width: '100%',
              height: '30%',
              background: '#d1d1d1',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
              boxShadow: '0 8px 25px rgba(0,0,0,0.2)',
              position: 'relative',
              zIndex: 11,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '1%',
              marginBottom: '2%',
            }}
          >
            <Box
              sx={{
                width: '95%',
                height: '65%',
                background: '#2b2b2b',
                borderRadius: '8px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: 'inset 0px -4px 8px rgba(0,0,0,0.3)',
                padding: '1%',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(15, 1fr)',
                  gridTemplateRows: 'repeat(5, 1fr)',
                  gap: '4px',
                }}
              >
                {[...Array(75)].map((_, index) => {
                  // Define special keys that should span multiple columns
                  const specialKeys = [0, 14, 15, 29, 30, 44, 45, 59, 60, 74]
                  let gridColumn = 'auto' // Default value

                  if (specialKeys.includes(index)) {
                    gridColumn = 'span 2' // Make special keys span 2 columns
                  }

                  return (
                    <Box
                      key={index}
                      sx={{
                        background: '#6b6b6b',
                        borderRadius: '4px',
                        boxShadow: 'inset 0px -2px 2px rgba(0,0,0,0.5)',
                        width: '100%',
                        height: '100%',
                        gridColumn: gridColumn,
                      }}
                    />
                  )
                })}
              </Box>
            </Box>

            <Box
              sx={{
                width: '35%',
                height: '15%',
                background: '#a0a0a0',
                borderRadius: '6px',
                marginTop: '2%',
                boxShadow: 'inset 0px 2px 4px rgba(0,0,0,0.3)',
              }}
            />
          </Box>
        ) */}
      </Box>
    </Box>
  )
}

export default VideoContainer
