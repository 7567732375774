import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import React, { useMemo } from 'react'
import { useGeneralInfoContext } from '../../GeneralInfoContext'
import { MajorEventType } from '../../utils/sentimentAnalysis'

interface MajorEventsTableProps {
  majorEvents: MajorEventType[]
  onEventClick: (event: MajorEventType, index: number) => void
  scrollToChart: () => void
}

const MajorEventsTable: React.FC<MajorEventsTableProps> = ({
  majorEvents,
  onEventClick,
  scrollToChart,
}) => {
  const { users } = useGeneralInfoContext()

  const updateDescription = (text: string) => {
    if (!text || !users) return text

    const nameMap = users.reduce<Record<string, string>>(
      (acc, user) => ({
        ...acc,
        [user.username]: user.name,
        [user.name]: user.name,
        ...(user.isMe ? { 'User 1': user.name } : { 'User 2': user.name }),
      }),
      {}
    )

    let updatedText = text
    Object.entries(nameMap).forEach(([oldName, newName]) => {
      const regex = new RegExp(`\\b${oldName}\\b`, 'gi')
      updatedText = updatedText.replace(regex, () => newName)
    })

    return updatedText
  }

  const sortedEvents = useMemo(() => {
    return [...majorEvents].sort((a, b) => {
      const dateA = a?.timestamp_range?.start
        ? new Date(a.timestamp_range.start)
        : new Date(0)
      const dateB = b?.timestamp_range?.start
        ? new Date(b.timestamp_range.start)
        : new Date(0)
      return dateA.getTime() - dateB.getTime()
    })
  }, [majorEvents])

  const renderDate = (event: MajorEventType) => {
    if (event.timestamp_range && event.timestamp_range.start) {
      try {
        const date = format(
          parseISO(event.timestamp_range.start),
          'MMM d, yyyy'
        )
        return date
      } catch (error) {
        return 'Invalid date'
      }
    }
    return 'N/A'
  }

  const renderSummary = (event: MajorEventType) => {
    if (event.event_deep_dive && typeof event.event_deep_dive === 'object') {
      return (
        updateDescription(event.event_deep_dive.event_summary) ||
        'No summary available'
      )
    }
    return 'No summary available'
  }

  const renderEventName = (event: MajorEventType) => {
    if (event.event && typeof event.event === 'string') {
      return updateDescription(event.event)
    }
    return 'Unknown Event'
  }

  const renderTableRows = () => {
    if (!Array.isArray(majorEvents) || majorEvents.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            No major events{' '}
          </TableCell>
        </TableRow>
      )
    }

    const rows = []
    const midpoint = Math.ceil(sortedEvents.length / 2)

    for (let i = 0; i < midpoint; i++) {
      const leftEvent = sortedEvents[i]
      const rightEvent = sortedEvents[i + midpoint]

      rows.push(
        <TableRow key={i}>
          {/* Left Column Event */}
          <TableCell>{renderDate(leftEvent)}</TableCell>
          <TableCell
            onClick={() => {
              console.log('Clicked left event', leftEvent)
              onEventClick(leftEvent, sortedEvents.indexOf(leftEvent))
              scrollToChart()
            }}
            sx={{ cursor: 'pointer', color: 'primary.main' }}
          >
            {renderEventName(leftEvent)}
          </TableCell>
          <TableCell>{renderSummary(leftEvent)}</TableCell>

          {/* Right Column Event (if exists) */}
          {rightEvent ? (
            <>
              <TableCell>{renderDate(rightEvent)}</TableCell>
              <TableCell
                onClick={() => {
                  onEventClick(rightEvent, sortedEvents.indexOf(rightEvent))
                  scrollToChart()
                }}
                sx={{ cursor: 'pointer', color: 'primary.main' }}
              >
                {renderEventName(rightEvent)}
              </TableCell>
              <TableCell>{renderSummary(rightEvent)}</TableCell>
            </>
          ) : (
            <>
              <TableCell colSpan={3}></TableCell>
            </>
          )}
        </TableRow>
      )
    }
    return rows
  }

  return (
    <TableContainer component={Paper} sx={{ mb: 4 }}>
      <Typography variant="h6" align="center" sx={{ py: 2 }}>
        Major Events
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Date</strong>
            </TableCell>
            <TableCell>
              <strong>Event</strong>
            </TableCell>
            <TableCell>
              <strong>Summary</strong>
            </TableCell>
            <TableCell>
              <strong>Date</strong>
            </TableCell>
            <TableCell>
              <strong>Event</strong>
            </TableCell>
            <TableCell>
              <strong>Summary</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default MajorEventsTable
