import { Box, Paper, Typography, useTheme } from '@mui/material'
import philosophicalVibesImage from './Philosophical_Vibes_at_Dusk.jpg'

const MoodStoryline = () => {
  const theme = useTheme()

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        bgcolor: theme.palette.background.paper,
        borderRadius: 2,
        height: '100%',
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '80%' }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}
        >
          Philosophical Vibes at Dusk
        </Typography>

        <Box sx={{ display: 'flex', flexGrow: 1, mb: 2 }}>
          <Box sx={{ flex: 1, pr: 2 }}>
            <img
              src={philosophicalVibesImage}
              alt="Philosophical Vibes at Dusk"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: theme.shape.borderRadius,
              }}
            />
          </Box>
          <Box
            sx={{ flex: 1, pl: 2, display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant="body2" paragraph sx={{ mb: 2 }}>
              This exquisite artwork, dear viewers, invites you to explore the
              intricate relationship between existential thought and
              contemporary technology, echoing the profound conversations shared
              between two intellectual friends. The floating books serve as
              modern-day Platonic ideals, signifying the weight of knowledge
              amidst the ephemeral nature of life. As you bask in the hues of
              twilight, ponder the delicate threads of connection woven into the
              fabric of human experience, from the simple joy of a grassy hill
              to the towering depths of academia's skyline.
            </Typography>

            <Paper
              elevation={2}
              sx={{
                p: 1,
                bgcolor: theme.palette.grey[100],
                borderRadius: 2,
                mt: 'auto',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ mb: 1, fontWeight: 'bold' }}
              >
                Inspired Verse:
              </Typography>
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                In the twilight's gentle shade,
                <br />
                Two friends embrace the night they made.
                <br />
                With thoughts like birds that soar and glide,
                <br />
                In pages turned, their worlds collide.
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default MoodStoryline
