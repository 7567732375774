import { MajorEventType } from '../../../utils/sentimentAnalysis'

const BASE_WEIGHTS = {
  CURRENT_WEEK: 0.5, // a
  PREVIOUS_WEEK: 0.25, // b
  TWO_WEEKS_AGO: 0.15, // c
  NO_EVENTS: 0.1, // d
} as const

// Ensure that the weights sum up to 1
const TOTAL_WEIGHT =
  BASE_WEIGHTS.CURRENT_WEEK +
  BASE_WEIGHTS.PREVIOUS_WEEK +
  BASE_WEIGHTS.TWO_WEEKS_AGO +
  BASE_WEIGHTS.NO_EVENTS

if (TOTAL_WEIGHT !== 1) {
  throw new Error('Weights do not sum up to 1')
}

interface WeightedData {
  [weekStart: string]: number
}

export function calculateWeightedSentiment(
  chatSentiments: Record<string, Record<string, number>>,
  majorEvents: Record<string, MajorEventType[]>
): WeightedData {
  const weightedData: WeightedData = {}
  const globalSentiments = chatSentiments['global']
  console.log(globalSentiments)

  Object.entries(globalSentiments).forEach(([weekStart, weekSentiment]) => {
    // Define week boundaries in milliseconds
    const currentWeekStart = new Date(weekStart).getTime()
    const currentWeekEnd = currentWeekStart + 7 * 24 * 60 * 60 * 1000
    const prevWeekStart = currentWeekStart - 7 * 24 * 60 * 60 * 1000
    const twoWeeksAgoStart = currentWeekStart - 14 * 24 * 60 * 60 * 1000

    // Initialize sets
    const EW: { sentiment: number; hash: string }[] = []
    const EW_1: { sentiment: number; hash: string }[] = []
    const EW_2: { sentiment: number; hash: string }[] = []
    const EN0: { sentiment: number; hash: string }[] = []

    // Track which hashes have been assigned to prevent duplication
    const assignedHashes = new Set<string>()

    // Process major events and assign to appropriate sets
    majorEvents['global'].forEach((event) => {
      if (event.major_score < 8 || !event.hash) return

      const eventTime = new Date(event.timestamp_range.start).getTime()
      console.log(new Date(eventTime), new Date(currentWeekStart))
      const hash = event.hash

      if (eventTime >= currentWeekStart && eventTime < currentWeekEnd) {
        if (!assignedHashes.has(hash)) {
          EW.push({ sentiment: weekSentiment, hash })
          assignedHashes.add(hash)
        }
      } else if (eventTime >= prevWeekStart && eventTime < currentWeekStart) {
        if (!assignedHashes.has(hash)) {
          EW_1.push({ sentiment: weekSentiment, hash })
          assignedHashes.add(hash)
        }
      } else if (eventTime >= twoWeeksAgoStart && eventTime < prevWeekStart) {
        if (!assignedHashes.has(hash)) {
          EW_2.push({ sentiment: weekSentiment, hash })
          assignedHashes.add(hash)
        }
      }
    })
    console.log(EW, EW_1, EW_2, EN0)

    // Assign to EN0 if no events in any category
    if (EW.length === 0 && EW_1.length === 0 && EW_2.length === 0) {
      EN0.push({ sentiment: weekSentiment, hash: 'no-events' })
    }

    // Calculate average sentiments for each category
    const avgEW =
      EW.length > 0
        ? EW.reduce((sum, chat) => sum + chat.sentiment, 0) / EW.length
        : 0
    const avgEW1 =
      EW_1.length > 0
        ? EW_1.reduce((sum, chat) => sum + chat.sentiment, 0) / EW_1.length
        : 0
    const avgEW2 =
      EW_2.length > 0
        ? EW_2.reduce((sum, chat) => sum + chat.sentiment, 0) / EW_2.length
        : 0
    const avgEN0 =
      EN0.length > 0
        ? EN0.reduce((sum, chat) => sum + chat.sentiment, 0) / EN0.length
        : 0

    // Determine which categories are present
    let sumWeightsPresent = 0
    const normalizedWeights: Partial<
      Record<keyof typeof BASE_WEIGHTS, number>
    > = {}

    if (EW.length > 0) {
      normalizedWeights.CURRENT_WEEK = BASE_WEIGHTS.CURRENT_WEEK
      sumWeightsPresent += BASE_WEIGHTS.CURRENT_WEEK
    }
    if (EW_1.length > 0) {
      normalizedWeights.PREVIOUS_WEEK = BASE_WEIGHTS.PREVIOUS_WEEK
      sumWeightsPresent += BASE_WEIGHTS.PREVIOUS_WEEK
    }
    if (EW_2.length > 0) {
      normalizedWeights.TWO_WEEKS_AGO = BASE_WEIGHTS.TWO_WEEKS_AGO
      sumWeightsPresent += BASE_WEIGHTS.TWO_WEEKS_AGO
    }
    if (EN0.length > 0) {
      normalizedWeights.NO_EVENTS = BASE_WEIGHTS.NO_EVENTS
      sumWeightsPresent += BASE_WEIGHTS.NO_EVENTS
    }

    // Normalize the weights so they sum up to 1
    Object.keys(normalizedWeights).forEach((key) => {
      const weightKey = key as keyof typeof BASE_WEIGHTS
      normalizedWeights[weightKey] =
        normalizedWeights[weightKey]! / sumWeightsPresent
    })

    // Calculate the weighted sentiment
    let weightedSentiment = 0

    if (normalizedWeights.CURRENT_WEEK && EW.length > 0) {
      weightedSentiment += normalizedWeights.CURRENT_WEEK * avgEW
    }
    if (normalizedWeights.PREVIOUS_WEEK && EW_1.length > 0) {
      weightedSentiment += normalizedWeights.PREVIOUS_WEEK * avgEW1
    }
    if (normalizedWeights.TWO_WEEKS_AGO && EW_2.length > 0) {
      weightedSentiment += normalizedWeights.TWO_WEEKS_AGO * avgEW2
    }
    if (normalizedWeights.NO_EVENTS && EN0.length > 0) {
      weightedSentiment += normalizedWeights.NO_EVENTS * avgEN0
    }

    // Assign to weightedData
    weightedData[weekStart] = weightedSentiment

    console.log(`Week ${weekStart} calculation:`, {
      sentimentSets: {
        EW,
        EW_1,
        EW_2,
        EN0,
      },
      averages: {
        avgEW,
        avgEW1,
        avgEW2,
        avgEN0,
      },
      sumWeightsPresent,
      normalizedWeights,
      weightedSentiment,
    })
  })

  return weightedData
}
