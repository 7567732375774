import styled from '@emotion/styled'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import { Upload as UploadIcon } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGeneralInfoContext } from '../../GeneralInfoContext'
import { EngagementData } from '../../utils/engagementLogic'
import { getOwnedFiles } from '../../utils/hashAuthentication'
import { mutateS3Cache } from '../../utils/mutateS3Cache'
import { deleteFile, requestFile } from '../../utils/s3cache'
import { checkFileExists, uploadJsonToS3 } from '../../utils/s3Storage'
import { MajorEventType } from '../../utils/sentimentAnalysis'
import { ChatUser, SentimentData } from '../../utils/types'
import ExportInstructions from '../ExportInstructions'
import {
  withFileUploadAndParse,
  WithFileUploadAndParseProps,
} from '../FileUploadAndParse'
import { useShareFunctionality } from '../ShareIconButton' // Add this import
import DashboardLayout from './components/DashboardLayout'
import { GlobalPersonalityType } from './components/GlobalPersonalityType'
import GlobalSentimentChart from './components/GlobalSentimentChart'
import UserNameAndIdentityDialog from './components/UserNameAndIdentityDialog'

// More distinct colors with the same light, soft style
const categoryColors: Record<string, string> = {
  friendship: '#B3DBFF', // Light blue
  professional: '#000000', // Pure black
  romance: '#FFB3B3', // Soft pastel red/pink
  family: '#B8E6B8', // Soft pastel green
  default: '#F5F5F5', // Light grey
}

interface MainContentProps {
  chats: {
    id: string
    name: string
    date: string
    category: string // Add this line
  }[]
  handleUploadSuccess: (chatId: string) => void
  personalityType: GlobalPersonalityType
}

const AuthenticatedUploadComponent: React.FC<WithFileUploadAndParseProps> = ({
  onFileUpload,
  isLoading,
  error,
  fileInputRef,
}) => {
  const theme = useTheme()

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    if (files && files.length > 0) {
      onFileUpload(files[0])
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Box
      onClick={() => fileInputRef.current?.click()}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      sx={{
        p: 4,
        backgroundColor: 'transparent',
        border: `3px dotted ${theme.palette.primary.main}`,
        borderRadius: 2,
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 200,
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
      }}
    >
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => e.target.files && onFileUpload(e.target.files[0])}
        style={{ display: 'none' }}
        accept=".txt,.zip,.json,.db"
      />
      {isLoading ? (
        <>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Uploading...
          </Typography>
        </>
      ) : (
        <>
          <UploadIcon
            size={48}
            color={theme.palette.primary.main}
            style={{ marginBottom: 16 }}
          />
          <Typography variant="h6" gutterBottom>
            Click or drag file to upload
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Supports .txt, .zip, .json, and .db files
          </Typography>
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

const EnhancedUploadComponent = withFileUploadAndParse(
  AuthenticatedUploadComponent
)

type ChatImage = {
  imageUrl: string
  hash: string
  category: string
}

const Overlay = styled(Box)`
  position: absolute;
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`

const MainContent: React.FC<MainContentProps> = ({
  chats,
  handleUploadSuccess,
  personalityType,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [chatImages, setChatImages] = useState<ChatImage[]>([])
  const { setHash, setToken } = useGeneralInfoContext()
  const [ownedHashes, setOwnedHashes] = useState<string[]>([])
  const [engagementData, setEngagementData] = useState<EngagementData[]>([])
  const [sentimentData, setSentimentData] = useState<SentimentData[]>([])
  const [numMessages, setNumMessages] = useState<number>(0)
  const [majorEventsMe, setMajorEventsMe] = useState<MajorEventType[]>([])
  const [users, setUsers] = useState<ChatUser[][]>([])
  const [showExportInstructions, setShowExportInstructions] = useState(false)
  const [openUserModal, setOpenUserModal] = useState(false)
  const { shareAction, ShareModal } = useShareFunctionality()

  useEffect(() => {
    async function fetchOwnedHashes() {
      const hashes = await getOwnedFiles()
      setOwnedHashes(hashes)
    }
    fetchOwnedHashes()
  }, [])

  useEffect(() => {
    async function getChatImages() {
      const chatImages = await Promise.all(
        ownedHashes.map(async (hash) => {
          // Get image
          const exists = await checkFileExists(`chat/${hash}/image-small.png`)
          const path = exists
            ? `chat/${hash}/image-small.png`
            : `chat/${hash}/image.png`
          const imageUrl = await requestFile(
            path,
            hash,
            'fake token',
            () => Promise.resolve('fake token'),
            true
          )

          // Get category from image-prompt.json
          let category = 'default'
          try {
            const imagePromptData = await requestFile(
              `chat/${hash}/image-prompt.json`,
              hash,
              'fake token',
              () => Promise.resolve('fake token'),
              false
            )
            category = imagePromptData?.chatCategory?.toLowerCase() || 'default'
          } catch (error) {
            console.error(`Failed to fetch category for hash ${hash}:`, error)
          }

          return { imageUrl, hash, category }
        })
      )
      setChatImages(chatImages)
    }
    getChatImages()
  }, [ownedHashes])

  useEffect(() => {
    if (engagementData.length > 0) {
      const meIndex = users.map((u) => {
        if (!u?.every((user) => user?.username)) {
          return null
        }
        u.sort((a, b) => a.username.localeCompare(b.username))
        if (u.length !== 2) {
          return null
        }
        // Get the actual username of "me"
        if (u[0].isMe || u[0].username === 'me') {
          return u[0].username
        }
        if (u[1].isMe || u[1].username === 'me') {
          return u[1].username
        }
        return null
      })

      let numMessages = 0
      engagementData.forEach((data, index) => {
        // Skip if we can't determine which user is "me"
        const relevantIndex = meIndex[index]
        if (relevantIndex === null) return

        data.chartData.forEach((d) => {
          if (d.numMessages) {
            // Use the actual username to get message count
            numMessages += d.numMessages[relevantIndex] || 0
          }
        })
      })

      setNumMessages(Math.round(numMessages))
    }
  }, [engagementData, users])

  // get the engagement data
  useEffect(() => {
    async function getEngagementData() {
      const engagementData = await Promise.all(
        ownedHashes.map(async (hash) => {
          try {
            const data = await requestFile(
              `chat/${hash}/engagement.json`,
              hash,
              'fake token',
              () => Promise.resolve('fake token'),
              false
            )
            return data
          } catch (error) {
            console.error(
              `Failed to fetch engagement data for hash ${hash}:`,
              error
            )
            return null
          }
        })
      )
      setEngagementData(engagementData.filter(Boolean))
    }
    getEngagementData()
  }, [ownedHashes])

  useEffect(() => {
    async function getSentimentData() {
      const sentimentData = await Promise.all(
        ownedHashes.map(async (hash) => {
          try {
            const data = await requestFile(
              `chat/${hash}/sentiment.json`,
              hash,
              'fake token',
              () => Promise.resolve('fake token'),
              false
            )
            return data
          } catch (error) {
            console.error(
              `Failed to fetch engagement data for hash ${hash}:`,
              error
            )
            return null
          }
        })
      )
      setSentimentData(sentimentData.filter(Boolean))
    }
    getSentimentData()
  }, [ownedHashes])

  useEffect(() => {
    async function getUsers() {
      const users = await Promise.all(
        ownedHashes.map(async (hash) =>
          requestFile(
            `chat/${hash}/people.json`,
            hash,
            'fake',
            () => Promise.resolve('fake'),
            false
          )
        )
      )
      setUsers(users as ChatUser[][])
    }
    getUsers()
  }, [ownedHashes])

  // Add these new states for context menu
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
    hash: string
  } | null>(null)

  // Add these handlers for context menu
  const handleContextMenu = (event: React.MouseEvent, hash: string) => {
    event.preventDefault()
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      hash,
    })
  }

  const handleContextMenuClose = () => {
    setContextMenu(null)
  }

  // Add state for the confirmation dialog
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [activeHash, setActiveHash] = useState<string | null>(null)

  const getSpecificUsers = (hash: string) => {
    // get the index of the hash in ownedHashes
    const index = ownedHashes.indexOf(hash)
    return users[index] || []
  }

  const setSpecificUsers = (hash: string, users: ChatUser[]) => {
    // get the index of the hash in ownedHashes
    const index = ownedHashes.indexOf(hash)
    setUsers((prevUsers) => {
      const newUsers = [...prevUsers]
      newUsers[index] = users
      return newUsers
    })
  }

  const handleDeleteConfirmation = (hash: string) => {
    setActiveHash(hash)
    setConfirmDeleteOpen(true)
  }

  const handleConfirmDelete = async () => {
    if (activeHash) {
      console.log('deleting', activeHash)
      await deleteFile(activeHash, `chat/${activeHash}`, 'fake', () =>
        Promise.resolve('fake')
      )
      // remove from both states
      setOwnedHashes(ownedHashes.filter((hash) => hash !== activeHash))
      setChatImages(chatImages.filter((chat) => chat.hash !== activeHash))
    }
    setConfirmDeleteOpen(false)
    setActiveHash(null)
    handleContextMenuClose()
  }

  const handleEditNames = (updatedUsers: ChatUser[]) => {
    setSpecificUsers(activeHash || '', updatedUsers)
    setOpenUserModal(false)
    uploadJsonToS3(`chat/${activeHash}/people.json`, updatedUsers)
    mutateS3Cache(activeHash || '', `chat/:hash:/people.json`)
  }

  const averageSentiment = useMemo(() => {
    // Filter out any null/undefined sentiment data first
    const validSentimentData = sentimentData.filter(Boolean)

    const paredDown = validSentimentData.map((d) =>
      d.sentiments.map((s) => {
        return {
          weekStart: s.weekStart,
          X_sentiment: s.X_sentiment,
          Z_sentiment: s.Z_sentiment,
          X_justification: s.X_justification,
          Z_justification: s.Z_justification,
        }
      })
    )

    // Filter out any null/undefined engagement data
    const validEngagementData = engagementData.filter(Boolean)

    const meIndex = users.map((u) => {
      if (!u?.every((user) => user?.username)) {
        return null
      }
      u.sort((a, b) => a.username.localeCompare(b.username))
      if (u.length !== 2) {
        return null
      }
      if (u[0].isMe || u[0].username === 'me') {
        return 0
      }
      if (u[1].isMe || u[1].username === 'me') {
        return 1
      }
      return null
    })

    const products: Record<string, number> = {}
    const normalization: Record<string, number> = {}
    paredDown.forEach((d) => {
      d.forEach((s) => {
        products[s.weekStart] = 0
        normalization[s.weekStart] = 0
      })
    })

    const meSentiment = paredDown.map((d, index) => {
      if (meIndex[index] === null) {
        return null
      }
      if (meIndex[index] === 0) {
        return d.map((s) => ({
          weekStart: s.weekStart,
          sentiment: s.X_sentiment,
        }))
      }
      return d.map((s) => ({
        weekStart: s.weekStart,
        sentiment: s.Z_sentiment,
      }))
    })

    meSentiment.forEach((d, index) => {
      if (!d || !validEngagementData[index]?.chartData) {
        return
      }
      d.forEach((s) => {
        const relevantWeek = validEngagementData[index].chartData.find(
          (w) => w.weekStart === s.weekStart
        )
        if (relevantWeek?.numMessages) {
          const numMessages = Object.values(relevantWeek.numMessages).reduce(
            (acc, val) => acc + val,
            0
          )

          products[s.weekStart] += s.sentiment * numMessages
          normalization[s.weekStart] += numMessages
        }
      })
    })

    Object.keys(products).forEach((weekStart) => {
      products[weekStart] /= normalization[weekStart] || 1 // Prevent division by zero
    })

    return products
  }, [sentimentData, users, engagementData])

  // Update this effect to track major events for "me"
  useEffect(() => {
    if (sentimentData.length > 0) {
      const meIndex = users.map((u) => {
        if (!u?.every((user) => user?.username)) {
          return null
        }
        u.sort((a, b) => a.username.localeCompare(b.username))
        if (u.length !== 2) {
          return null
        }
        // Get the actual username of "me"
        if (u[0].isMe || u[0].username === 'me') {
          return 0
        }
        if (u[1].isMe || u[1].username === 'me') {
          return 1
        }
        return null
      })

      const allMajorEvents = sentimentData.reduce((acc, data, index) => {
        if (!data?.allMajorEvents?.length || meIndex[index] === null) {
          return acc
        }

        // Filter events where subject is "Both" or matches my position (X for index 0, Z for index 1)

        const mySentimentKey =
          meIndex[index] === 0 ? 'X_sentiment' : 'Z_sentiment'

        const mySentimentsWeekly = data.sentiments.map((s) => {
          return {
            weekStart: s.weekStart,
            sentiment: s[mySentimentKey],
          }
        })

        let myEvents = data.allMajorEvents.filter((event) => {
          const myIdentifier = meIndex[index] === 0 ? 'X' : 'Z'
          return (
            event.major_score >= 8 &&
            (event.subject === 'Both' || event.subject === myIdentifier)
          )
        })

        const isInTheRightWeek = (
          weekStart: string,
          eventWeekStart: string
        ) => {
          // check if it's after weekStart and before weekStart + 7 daysjj
          const weekStartDate = new Date(weekStart)
          const eventWeekStartDate = new Date(eventWeekStart)
          return (
            eventWeekStartDate >= weekStartDate &&
            eventWeekStartDate <
              new Date(weekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)
          )
        }

        myEvents = myEvents.map((event) => ({
          ...event,
          hash: ownedHashes[index],
          sentiment: mySentimentsWeekly.find((s) =>
            isInTheRightWeek(s.weekStart, event.timestamp_range.start)
          )?.sentiment,
        }))

        return [...acc, ...myEvents]
      }, [] as MajorEventType[])

      // sort by major_score decreasing
      allMajorEvents.sort((a, b) => b.major_score - a.major_score)
      console.log('All major events:', allMajorEvents)

      // console.log('All major events:', allMajorEvents) // This will show all the events in the console
      setMajorEventsMe([...allMajorEvents])
    }
  }, [sentimentData, users])

  // Add this near your other useMemo hooks
  const chatNames = useMemo(() => {
    const names: Record<string, string> = {};
    ownedHashes.forEach((hash, index) => {
      const chatUsers = users[index];
      if (chatUsers && chatUsers.length === 2) {
        names[hash] = chatUsers.map(user => user.name).join(' & ');
      }
    });
    return names;
  }, [ownedHashes, users]);

  return (
    <DashboardLayout
      ownedHashesLength={ownedHashes.length}
      numMessages={numMessages}
      numMajorEvents={majorEventsMe.length || 0}
      personalityType={personalityType}
    >
      {/* Global Sentiment Chart */}
      <Box sx={{ mb: 4 }}>
        <GlobalSentimentChart
          sentimentData={averageSentiment}
          majorEvents={majorEventsMe.sort(
            (a, b) =>
              new Date(a.timestamp_range.start).getTime() -
              new Date(b.timestamp_range.start).getTime()
          )}
          numChats={ownedHashes.length}
          chatNames={chatNames}
        />
      </Box>

      {/* File Upload Area */}
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{ mb: 2, textAlign: 'center' }}
        >
          Upload a New Chat
        </Typography>
        <EnhancedUploadComponent onUploadSuccess={handleUploadSuccess} />
      </Box>

      {/* Export Instructions */}
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => setShowExportInstructions(!showExportInstructions)}
          sx={{
            p: 0,
            minWidth: 'auto',
            textTransform: 'none',
            fontSize: '0.875rem',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          How to export
        </Button>
        {showExportInstructions && <ExportInstructions />}
      </Box>

      {/* Chat Squares */}
      <Typography
        variant="h5"
        component="h2"
        sx={{ mb: 2, textAlign: 'center' }}
      >
        Your Chats
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {chatImages.map((chatImage) => (
          <Grid item key={chatImage.hash}>
            <Box
              component="figure"
              sx={{
                width: 160,
                height: 160,
                m: 0,
                position: 'relative',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: theme.shadows[4] as string,
                },
              }}
              onClick={() => {
                setHash(chatImage.hash)
                setToken('fake')
                navigate(`/main`)
              }}
              onContextMenu={(e: React.MouseEvent<HTMLDivElement>) =>
                handleContextMenu(e, chatImage.hash)
              }
            >
              <img
                src={chatImage.imageUrl}
                onLoad={(e) => {
                  const img = e.target as HTMLImageElement
                  img.style.padding = '8px'
                  img.style.backgroundColor =
                    categoryColors[chatImage.category || 'default']
                  img.style.borderRadius = '8px'
                  img.style.transition = 'filter 0.3s ease'

                  // Add hover effect for blur and overlay
                  const parent = img.parentElement
                  if (parent) {
                    const overlay = parent.querySelector(
                      '.overlay'
                    ) as HTMLElement
                    parent.addEventListener('mouseenter', () => {
                      img.style.filter = 'blur(5px)'
                      if (overlay) overlay.style.opacity = '1'
                    })
                    parent.addEventListener('mouseleave', () => {
                      img.style.filter = 'none'
                      if (overlay) overlay.style.opacity = '0'
                    })
                  }
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                alt="chat"
              />
              <Overlay className="overlay">
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    fontFamily: "'Comfortaa', sans-serif",
                    fontSize: '1.1rem',
                    letterSpacing: '0.02em',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                    width: '100%',
                    textAlign: 'center',
                    color: 'white',
                    px: 2,
                  }}
                >
                  {getSpecificUsers(chatImage.hash)
                    .map((user) => user.name)
                    .join(' & ')}
                </Typography>
              </Overlay>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Modify the context menu */}
      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            shareAction(contextMenu?.hash)
            handleContextMenuClose()
          }}
        >
          Share Dashboard
        </MenuItem>
        <MenuItem
          onClick={() => {
            setActiveHash(contextMenu?.hash || '')
            setOpenUserModal(true)
            handleContextMenuClose()
          }}
        >
          Edit Names
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteConfirmation(contextMenu?.hash || '')
            handleContextMenuClose()
          }}
          sx={{ color: 'error.main' }}
        >
          Delete Chat
        </MenuItem>
      </Menu>

      {/* Add ShareModal component */}
      <ShareModal />

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this chat? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <UserNameAndIdentityDialog
        key={getSpecificUsers(activeHash || '')
          .map((u) => u.name + u.username + u.isMe)
          .join(',')}
        open={openUserModal}
        users={getSpecificUsers(activeHash || '')}
        onClose={() => setOpenUserModal(false)}
        onNameChange={handleEditNames}
      />
    </DashboardLayout>
  )
}

export default MainContent
