import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { formatDate } from '../utils/general'
import { ZipFile } from './FileUploadAndParse'

export const ZipFileDialog = ({
  isOpen,
  onClose,
  zipFiles,
  onFileSelect,
}: {
  isOpen: boolean
  onClose: () => void
  zipFiles: ZipFile[]
  onFileSelect: (fileName: string) => void
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth="md"
    fullWidth
    PaperProps={{
      style: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    }}
  >
    <DialogTitle>Select a file from the zip</DialogTitle>
    <DialogContent>
      <List sx={{ maxHeight: '60vh', overflow: 'auto' }}>
        {zipFiles.map((file) => (
          <ListItem
            button
            key={file.name}
            onClick={() => onFileSelect(file.name)}
            sx={{
              borderRadius: 1,
              mb: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <ListItemText
              primary={`${formatDate(file.date)}`}
              secondary={
                <span
                  dangerouslySetInnerHTML={{
                    __html: file.preview.replace(/\n/g, '<br>'),
                  }}
                />
              }
              primaryTypographyProps={{
                fontWeight: 'medium',
              }}
              secondaryTypographyProps={{
                noWrap: true,
                style: { maxWidth: '100%' },
              }}
            />
          </ListItem>
        ))}
      </List>
    </DialogContent>
  </Dialog>
)
