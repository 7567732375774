import { Global, css, keyframes } from '@emotion/react'
import { Box, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import StatisticsTiles from './StatisticsTiles'
import { personalityThemes } from './personalityThemes/themes'
import { PersonalityTheme, PersonalityType } from './personalityThemes/types'

interface DashboardLayoutProps {
  children: React.ReactNode
  ownedHashesLength: number
  numMessages: number
  numMajorEvents: number
  personalityType?: PersonalityType
}

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`

const DashboardContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'personalityTheme' && prop !== 'isPersonalized',
})<{ personalityTheme: PersonalityTheme; isPersonalized: boolean }>(
  ({ personalityTheme }) => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: personalityTheme.background.gradient,
    backgroundSize: '400% 400%',
    animation: `${gradientAnimation} 15s ease infinite`,
  })
)

const ContentContainer = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}))

const GlobalStyles = () => (
  <Global
    styles={css`
      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `}
  />
)

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  ownedHashesLength,
  numMessages,
  numMajorEvents,
  personalityType = 'none',
}) => {
  const personalityTheme = personalityThemes[personalityType]
  const isPersonalized = personalityType !== 'none'

  return (
    <>
      <GlobalStyles />
      <DashboardContainer
        personalityTheme={personalityTheme}
        isPersonalized={isPersonalized}
      >
        <ContentContainer maxWidth="xl">
          <StatisticsTiles
            numMessages={numMessages}
            numMajorEvents={numMajorEvents}
            ownedHashesLength={ownedHashesLength}
            personalityTheme={personalityTheme}
            isPersonalized={isPersonalized}
          />
          {children}
        </ContentContainer>
      </DashboardContainer>
    </>
  )
}

export default DashboardLayout
