import { Amplify } from 'aws-amplify'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_HrPYJPdzt',
      userPoolClientId: '58hvom2ifsfnm0svc5dopb93p7',
      loginWith: {
        oauth: {
          domain: 'mosaic-users.auth.us-east-1.amazoncognito.com',
          scopes: [
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin', // Add this line
          ],
          redirectSignIn: [process.env.REACT_APP_SIGN_IN_URL!],
          redirectSignOut: [process.env.REACT_APP_SIGN_OUT_URL!],
          responseType: 'code',
        },
      },
    },
  },
})
