import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { PersonalityTheme } from "./personalityThemes/types";

interface StatisticsTilesProps {
  numMessages: number
  numMajorEvents: number
  ownedHashesLength: number
  personalityTheme: PersonalityTheme
  isPersonalized: boolean
}

const getValueGradient = (
  title: string,
  value: string | number,
  numberGradients: PersonalityTheme["tiles"]["numberGradients"]
): string => {
  if (title === "Number of Messages Sent") {
    const numValue = Number(value.toString().replace(",", ""));
    if (numValue <= 10000) return numberGradients.low;
    if (numValue <= 100000) return numberGradients.medium;
    if (numValue <= 500000) return numberGradients.high;
    if (numValue <= 1000000) return numberGradients.veryHigh;
    return numberGradients.exceptional;
  }

  if (title === "Major Events") {
    const numValue = Number(value);
    if (numValue <= 50) return numberGradients.low;
    if (numValue <= 100) return numberGradients.medium;
    if (numValue <= 250) return numberGradients.high;
    if (numValue <= 500) return numberGradients.veryHigh;
    return numberGradients.exceptional;
  }

  if (title === "Conversations") {
    const numValue = Number(value);
    if (numValue <= 10) return numberGradients.low;
    if (numValue <= 25) return numberGradients.medium;
    if (numValue <= 50) return numberGradients.high;
    if (numValue <= 100) return numberGradients.veryHigh;
    return numberGradients.exceptional;
  }

  return numberGradients.medium; // default gradient
};

const StatisticsTiles: React.FC<StatisticsTilesProps> = ({
  numMessages,
  numMajorEvents,
  ownedHashesLength,
  personalityTheme,
  isPersonalized,
}) => {
  const theme = useTheme();

  return (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      {[
        {
          title: "Number of Messages Sent",
          value: numMessages,
        },
        {
          title: "Major Events",
          value: numMajorEvents,
        },
        {
          title: "Conversations",
          value: ownedHashesLength,
        },
      ].map((item) => (
        <Grid item xs={12} md={4} key={item.title}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              textAlign: "center",
              position: "relative",
              ...(isPersonalized && {
                backgroundColor: personalityTheme.tiles.backgroundColor,
                boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                backdropFilter: "blur(4px)",
              }),
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: isPersonalized
                  ? personalityTheme.tiles.titleColor
                  : "#666",
                fontSize: "0.9rem",
                textTransform: "uppercase",
                letterSpacing: "0.1em",
                mb: 1,
                fontFamily: isPersonalized
                  ? personalityTheme.tiles.fontFamily
                  : "inherit",
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                fontSize: "3rem",
                letterSpacing: "-0.02em",
                fontFamily: isPersonalized
                  ? personalityTheme.tiles.fontFamily
                  : "inherit",
                background: getValueGradient(
                  item.title,
                  item.value,
                  personalityTheme.tiles.numberGradients
                ),
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
              }}
            >
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatisticsTiles;
