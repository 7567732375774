import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'
import { fetchAuthSession } from 'aws-amplify/auth'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { amIAuthedOwner } from '../utils/hashAuthentication'
import { mutateS3Cache } from '../utils/mutateS3Cache'
import { uploadJsonToS3 } from '../utils/s3Storage'
import { ChatUser } from '../utils/types'
import MetricSwitcher from './MetricSwitcher'
import ShareIconButton from './ShareIconButton'
import UserNameEditDialog from './UserDashboard/components/UserNameEditDialog'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const slideInLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

const slideInRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isScrolled',
})<{ isScrolled: boolean }>(({ theme, isScrolled }) => ({
  background: theme.palette.background.paper,
  borderBottom: `.1px solid ${theme.palette.divider}`,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  padding: isScrolled ? theme.spacing(1, 0) : theme.spacing(3, 0),
  position: 'sticky',
  transition: 'all 0.3s ease-in-out',
  height: isScrolled ? '60px' : '110px',
  zIndex: theme.zIndex.appBar,
}))

const AppTitle = styled(Typography)<{ isScrolled: boolean }>(
  ({ theme, isScrolled }) => ({
    fontWeight: 'bold',
    fontFamily: "'Comfortaa', sans-serif",
    fontSize: isScrolled ? '1.5rem' : '2.5rem',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, #00C4FF, #60A5FA)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
    position: 'absolute',
    left: theme.spacing(2),
    top: isScrolled ? theme.spacing(-1) : theme.spacing(-2),
    transition: 'all 0.15s ease-in-out',
    opacity: isScrolled ? 0 : 1,
    cursor: 'pointer',
  })
)

const ShareIcon = styled(Typography)<{ isScrolled: boolean }>(
  ({ theme, isScrolled }) => ({
    fontWeight: 'bold',
    fontFamily: "'Comfortaa', sans-serif",
    fontSize: isScrolled ? '1.5rem' : '2.5rem',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, #00C4FF, #60A5FA)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
    position: 'absolute',
    right: theme.spacing(2),
    top: isScrolled ? theme.spacing(-1) : theme.spacing(-2),
    transition: 'all 0.15s ease-in-out',
    opacity: isScrolled ? 0 : 1,
  })
)

const UserNames = styled(Typography)<{ isScrolled: boolean }>(
  ({ theme, isScrolled }) => ({
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(0, 0, 0, 0.03)',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    fontSize: '0.875rem',
    position: 'absolute',
    left: isScrolled ? theme.spacing(2) : '0',
    transform: isScrolled ? 'translate(0, 0)' : 'translate(-100%, 0.3px)',
    top: isScrolled ? theme.spacing(0.5) : 'auto',
    bottom: isScrolled ? 'auto' : theme.spacing(0),
    transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
    opacity: isScrolled ? 1 : 0,
    animation: `${slideInLeft} 1.5s ease-out forwards`,
    animationDelay: isScrolled ? '0s' : '1.5s',
  })
)

const MetricText = styled(Box)<{ isScrolled: boolean }>(
  ({ theme, isScrolled }) => ({
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(0, 0, 0, 0.03)',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.875rem',
    position: 'absolute',
    left: '50%',
    transform: isScrolled ? 'translate(-50%, 0)' : 'translate(-50%, 0.3px)',
    top: isScrolled ? theme.spacing(0.5) : 'auto',
    bottom: isScrolled ? 'auto' : theme.spacing(0),
    transition: 'opacity 1s ease-out',
    opacity: isScrolled ? 1 : 0,
    animation: `${fadeIn} 1.5s ease-out forwards`,
    animationDelay: isScrolled ? '0s' : '1.5s',
  })
)

const WelcomeText = styled(Typography)<{ isScrolled: boolean }>(
  ({ theme, isScrolled }) => ({
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(0, 0, 0, 0.05)',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
    fontSize: '0.875rem',
    position: 'absolute',
    right: isScrolled ? theme.spacing(2) : '0',
    transform: isScrolled ? 'translateX(0)' : 'translateX(100%)',
    top: isScrolled ? theme.spacing(0.5) : 'auto',
    bottom: isScrolled ? 'auto' : theme.spacing(0),
    transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
    opacity: isScrolled ? 1 : 0,
    animation: `${slideInRight} 1.5s ease-out forwards`,
    animationDelay: isScrolled ? '0s' : '1.5s',
  })
)

const AuthButton = styled(Button)<{ isScrolled: boolean }>(
  ({ theme, isScrolled }) => ({
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(0, 0, 0, 0.03)',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    fontSize: '0.875rem',
    position: 'absolute',
    right: isScrolled ? theme.spacing(2) : '0',
    transform: isScrolled ? 'translateX(0)' : 'translateX(100%)',
    top: isScrolled ? theme.spacing(0.5) : 'auto',
    bottom: isScrolled ? 'auto' : theme.spacing(0),
    transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
    opacity: isScrolled ? 1 : 0,
    animation: `${slideInRight} 1.5s ease-out forwards`,
    animationDelay: isScrolled ? '0s' : '1.5s',
  })
)

const UploadButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isScrolled' && prop !== 'isPulsing',
})<{ isScrolled: boolean; isPulsing: boolean }>(
  ({ theme, isScrolled, isPulsing }) => ({
    position: 'absolute',
    right: theme.spacing(2),
    top: isScrolled ? theme.spacing(-1) : theme.spacing(-2),
    animation: isPulsing ? `${pulse} 2s infinite` : 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    padding: theme.spacing(0.5, 1),
    fontSize: '0.75rem',
    minWidth: 'auto',
    transition: 'all 0.15s ease-in-out',
    opacity: isScrolled ? 0 : 1,
  })
)

const Title: React.FC = () => {
  const navigate = useNavigate()
  const [showNames, setShowNames] = useState(false)
  const [showMetricSwitcher, setShowMetricSwitcher] = useState(false)
  const [showWelcome, setShowWelcome] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isPulsing, setIsPulsing] = useState(true)
  const { hash, file, users, setUsers, setHash, setToken } =
    useGeneralInfoContext()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [amIOwner, setAmIOwner] = useState(Boolean(file))
  const [isNameEditModalOpen, setIsNameEditModalOpen] = useState(false)

  useEffect(() => {
    if (file) {
      setAmIOwner(true)
    } else {
      amIAuthedOwner(hash || '').then(setAmIOwner)
    }
  }, [hash])

  const handleScroll = useCallback(() => {
    const scrollDownThreshold = 100
    const scrollUpThreshold = 50

    if (!isScrolled && window.scrollY > scrollDownThreshold) {
      setIsScrolled(true)
    } else if (isScrolled && window.scrollY < scrollUpThreshold) {
      setIsScrolled(false)
    }
  }, [isScrolled])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    const namesTimer = setTimeout(() => {
      setShowNames(true)
    }, 1000)

    const metricSwitcherTimer = setTimeout(() => {
      setShowMetricSwitcher(true)
    }, 2000)

    const welcomeTimer = setTimeout(() => {
      setShowWelcome(true)
    }, 3000)

    const pulseTimer = setTimeout(() => {
      setIsPulsing(false)
    }, 10000)

    // Check authentication status
    const checkAuth = async () => {
      try {
        const session = await fetchAuthSession()
        setIsAuthenticated(!!session.tokens)
      } catch (error) {
        console.error('Error checking auth state:', error)
        setIsAuthenticated(false)
      }
    }
    checkAuth()

    return () => {
      clearTimeout(namesTimer)
      clearTimeout(metricSwitcherTimer)
      clearTimeout(welcomeTimer)
      clearTimeout(pulseTimer)
    }
  }, [])

  const handleAuthClick = () => {
    navigate('/signin')
  }

  const handleAnalyzeAnotherChat = () => {
    setHash('')
    setToken('')
    navigate('/')
  }

  const handleNameChange = (updatedUsers: ChatUser[]) => {
    console.log('updatedUsers', updatedUsers)
    if (users) {
      setUsers([...updatedUsers])
      uploadJsonToS3(`chat/${hash}/people.json`, updatedUsers)
      mutateS3Cache(hash || '', `chat/:hash:/people.json`)
    }
  }

  return (
    <StyledAppBar position="sticky" elevation={0} isScrolled={isScrolled}>
      <Toolbar
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          height: '100%',
        }}
      >
        <AppTitle
          variant="h1"
          isScrolled={isScrolled}
          onClick={() => {
            setHash('')
            setToken('')
            navigate('/')
          }}
        >
          Mosaic
        </AppTitle>
        {amIOwner && (
          <ShareIcon variant="h1" isScrolled={isScrolled}>
            <ShareIconButton />
          </ShareIcon>
        )}
        {!amIOwner && (
          <UploadButton
            variant="contained"
            onClick={handleAnalyzeAnotherChat}
            isScrolled={isScrolled}
            isPulsing={isPulsing}
          >
            Analyze another chat
          </UploadButton>
        )}
        {showNames && users && users.length > 1 && (
          <UserNames
            isScrolled={isScrolled}
            onClick={() => setIsNameEditModalOpen(true)}
            sx={{ cursor: 'pointer' }}
          >
            <Typography component="span" sx={{ color: 'primary.main' }}>
              {users[0].name}
            </Typography>
            <Typography component="span" sx={{ color: 'text.secondary' }}>
              {' '}
              and{' '}
            </Typography>
            <Typography component="span" sx={{ color: 'secondary.main' }}>
              {users[1].name}
            </Typography>
          </UserNames>
        )}
        {showMetricSwitcher && (
          <MetricText isScrolled={isScrolled}>
            <Typography
              variant="subtitle1"
              sx={{ color: 'text.secondary', fontSize: '0.875rem' }}
            >
              <MetricSwitcher />
            </Typography>
          </MetricText>
        )}
        {showWelcome ? (
          isAuthenticated ? (
            <WelcomeText
              isScrolled={isScrolled}
              variant="body2"
              color="text.secondary"
            >
              Welcome to your chat insights
            </WelcomeText>
          ) : (
            <AuthButton
              variant="contained"
              color="primary"
              onClick={handleAuthClick}
              isScrolled={isScrolled}
              sx={{ color: 'blue' }}
            >
              Sign In / Sign Up
            </AuthButton>
          )
        ) : (
          <Box sx={{ height: '0px' }} />
        )}
        {users && users.length > 1 && (
          <UserNameEditDialog
            key={users.map((u) => u.name + u.username + u.isMe).join(',')}
            open={isNameEditModalOpen}
            users={users || []}
            onClose={() => setIsNameEditModalOpen(false)}
            onNameChange={handleNameChange}
          />
        )}
      </Toolbar>
    </StyledAppBar>
  )
}

export default Title
