import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import PrivacyPolicyModal from "../Documents/PrivacyPolicy";
import TermsOfServiceModal from "../Documents/TermsOfService";

const Footer: React.FC = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const handleOpenPrivacyModal = () => setIsPrivacyModalOpen(true);
  const handleClosePrivacyModal = () => setIsPrivacyModalOpen(false);
  const handleOpenTermsModal = () => setIsTermsModalOpen(true);
  const handleCloseTermsModal = () => setIsTermsModalOpen(false);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#222",
        color: "#fff",
        padding: "12px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "10px",
      }}
    >
      <Link
        href="mailto:contactus.mosaic@gmail.com"
        color="inherit"
        underline="hover"
        sx={{ fontSize: "0.875rem" }}
      >
        Reach out at: contactus.mosaic@gmail.com
      </Link>
      <Box>
        <Link
          component="button"
          color="inherit"
          underline="hover"
          onClick={handleOpenPrivacyModal}
          sx={{ fontSize: "0.875rem", marginRight: "10px" }}
        >
          Privacy Policy
        </Link>
        <Link
          component="button"
          color="inherit"
          underline="hover"
          onClick={handleOpenTermsModal}
          sx={{ fontSize: "0.875rem" }}
        >
          Terms of Service
        </Link>
      </Box>
      <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
        2024 Mosaic
      </Typography>
      <PrivacyPolicyModal
        open={isPrivacyModalOpen}
        onClose={handleClosePrivacyModal}
      />
      <TermsOfServiceModal
        open={isTermsModalOpen}
        onClose={handleCloseTermsModal}
      />
    </Box>
  );
};

export default Footer;
