import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';

interface PersonalityProfileProps {
  name: string;
  type: string;
  strengths: string[];
  weaknesses: string[];
  description: string;
}

const PersonalityProfile: React.FC<PersonalityProfileProps> = ({ name, type, strengths, weaknesses, description }) => (
  <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
    <Typography variant="h6" align="center" gutterBottom>{name}</Typography>
    <Typography variant="h2" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
      {type.split('').map((letter, index) => (
        <span key={index} style={{ color: index % 2 === 0 ? '#4285F4' : '#000000' }}>{letter}</span>
      ))}
    </Typography>
    <Grid container spacing={1} sx={{ mb: 2.5 }}>
      <Grid item xs={6}>
        <Typography variant="subtitle2" sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Personality strengths:</Typography>
        {strengths.map((strength, index) => (
          <Typography key={index} variant="body2" sx={{ fontSize: '0.8rem' }}>{strength}</Typography>
        ))}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2" sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Personality weaknesses:</Typography>
        {weaknesses.map((weakness, index) => (
          <Typography key={index} variant="body2" sx={{ fontSize: '0.8rem' }}>{weakness}</Typography>
        ))}
      </Grid>
    </Grid>
    <Paper 
      elevation={0} 
      sx={{ 
        backgroundColor: '#8E24AA', 
        color: 'white', 
        p: 1.5, 
        mt: 'auto',
        fontSize: '0.8rem',
        flexGrow: 1,
        overflow: 'auto'
      }}
    >
      <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>{description}</Typography>
    </Paper>
  </Paper>
);

const PersonalityInsights: React.FC = () => {
  return (
    <Box sx={{ height: '100%', p: 1, backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden' }}>
      <Grid container spacing={1} sx={{ height: '100%' }}>
        <Grid item xs={6} sx={{ height: '100%' }}>
          <PersonalityProfile
            name="Emily"
            type="ESFP"
            strengths={['Enthusiastic', 'Practical', 'Adaptable', 'Spontaneous']}
            weaknesses={['Easily bored', 'Unfocused', 'Conflict-avoidant', 'Sensitive to criticism']}
            description="Emily is the quintessential ESFP with a flair for spontaneity and socializing. Her vibrant social life spans wine parties to bowling nights, showcasing strength in connections. Beneath the charm lies procrastination and dark humor, hinting at existential musings. Emily turns mundane tasks into comedic gold, truly the life of the party—just don't expect punctual assignments."
          />
        </Grid>
        <Grid item xs={6} sx={{ height: '100%' }}>
          <PersonalityProfile
            name="John"
            type="ENTP"
            strengths={['Innovative', 'Adaptable', 'Charismatic', 'Quick-witted']}
            weaknesses={['Argumentative', 'Insensitive', 'Unfocused', 'Impatient']}
            description="John embodies the ENTP spirit, a whirlwind of ideas making mundane plans sound like heists. His sarcasm and knack for comedic roasts shine in spontaneous scenarios. However, his tendency to flit between ideas leads to unfinished projects and a chaotic schedule. He's the party's soul, but don't count on him for long-term commitments beyond last-minute dinner plans."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalityInsights;