import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { confirmSignUp, signUp, signInWithRedirect } from 'aws-amplify/auth'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SignUp: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)
    setIsLoading(true)

    try {
      await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      })
      setIsVerifying(true)
    } catch (error) {
      console.error('Sign up error:', error)
      if (error instanceof Error) {
        setError(`Sign up failed: ${error.name} - ${error.message}`)
      } else {
        setError('An unknown error occurred during sign up.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleVerification = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)
    setIsLoading(true)

    try {
      await confirmSignUp({ username, confirmationCode: verificationCode })
      navigate('/signin', { state: { username, justVerified: true } })
    } catch (error) {
      console.error('Verification error:', error)
      if (error instanceof Error) {
        setError(`Verification failed: ${error.name} - ${error.message}`)
      } else {
        setError('An unknown error occurred during verification.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      await signInWithRedirect({
        provider: 'Google'
      });
    } catch (error) {
      console.error('Google sign-in error:', error);
      setError(error instanceof Error ? error.message : 'Failed to sign in with Google');
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      await signInWithRedirect({
        provider: 'Facebook'
      });
    } catch (error) {
      console.error('Facebook sign-in error:', error);
      setError(error instanceof Error ? error.message : 'Failed to sign in with Facebook');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {isVerifying ? 'Verify Email' : 'Sign Up'}
        </Typography>
        <Box
          component="form"
          onSubmit={isVerifying ? handleVerification : handleSubmit}
          sx={{ mt: 1 }}
        >
          {!isVerifying ? (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </>
          ) : (
            <TextField
              margin="normal"
              required
              fullWidth
              id="verificationCode"
              label="Verification Code"
              name="verificationCode"
              autoFocus
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          )}
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : isVerifying ? (
              'Verify Email'
            ) : (
              'Sign Up'
            )}
          </Button>
          {!isVerifying && (
            <>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleSignIn}
                sx={{ mt: 2, mb: 1 }}
              >
                Continue with Google
              </Button>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<FacebookIcon />}
                onClick={handleFacebookSignIn}
                sx={{ mb: 2 }}
              >
                Continue with Facebook
              </Button>
              <Divider sx={{ mb: 2 }}>or</Divider>
            </>
          )}
          <Link href="/signin" variant="body2">
            {'Already have an account? Sign In'}
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

export default SignUp
