import React, { useState, useEffect, useCallback } from 'react';
import { Box, IconButton, Fade } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoodStoryline from './MoodStoryline';
import PersonalityInsights from './PersonalityInsights';
import RelationshipDynamics from './RelationshipDynamics';

const MacBookContainer = () => {
  const [activeModule, setActiveModule] = useState(0);
  const [nextModule, setNextModule] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const modules = [
    <MoodStoryline key="mood" />,
    <PersonalityInsights key="personality" />,
    <RelationshipDynamics key="relationship" />
  ];

  const changeModule = useCallback((direction = 1) => {
    setFadeIn(false);
    const next = (activeModule + direction + modules.length) % modules.length;
    setNextModule(next);
  }, [activeModule, modules.length]);

  const handlePrevious = () => changeModule(-1);

  useEffect(() => {
    if (!fadeIn) {
      const fadeOutTimer = setTimeout(() => {
        setActiveModule(nextModule);
        setFadeIn(true);
      }, 300); // Fade out duration
      return () => clearTimeout(fadeOutTimer);
    }
  }, [fadeIn, nextModule]);

  useEffect(() => {
    const interval = setInterval(() => {
      changeModule(1);
    }, 8000); // Change module every 8 seconds
    return () => clearInterval(interval);
  }, [changeModule]);

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px', // Increased from 800px to 1200px
          aspectRatio: '16 / 10',
          perspective: '1500px', // Increased for a more pronounced 3D effect
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            transform: 'rotateX(5deg) rotateY(-15deg)', // Slightly reduced Y rotation
            transformStyle: 'preserve-3d',
          }}
        >
          {/* Screen */}
          <Box
            sx={{
              width: '100%',
              height: '100%',
              background: '#000',
              borderRadius: '30px', // Increased for a smoother look on larger size
              padding: '2%',
              boxShadow: '0 15px 50px rgba(0,0,0,0.3)', // Increased shadow for larger size
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {/* Notch */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '12%', // Slightly reduced percentage for larger screen
                height: '1.5%', // Slightly reduced percentage for larger screen
                background: '#000',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                zIndex: 10,
              }}
            />
            {/* Screen Content */}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: 'linear-gradient(135deg, #ff8a00, #e52e71, #2ebf91)',
                borderRadius: '20px',
                padding: '20px', // Reduced padding
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Fade in={fadeIn} timeout={300}>
                <Box sx={{ height: '100%', overflow: 'auto' }}>
                  {modules[activeModule]}
                </Box>
              </Fade>
              {/* Navigation Button */}
              <IconButton
                onClick={handlePrevious}
                sx={{
                  position: 'absolute',
                  left: 20,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.5)' },
                  padding: '12px', // Increased padding for larger button
                }}
              >
                <ArrowBackIosNewIcon sx={{ fontSize: '2rem' }} /> {/* Increased icon size */}
              </IconButton>
              {/* Indicator Dots */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 20,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  display: 'flex',
                  gap: '15px', // Increased gap
                }}
              >
                {modules.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: 15,
                      height: 15,
                      borderRadius: '50%',
                      backgroundColor: index === activeModule ? 'white' : 'rgba(255, 255, 255, 0.5)',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          {/* MacBook Body */}
          <Box
            sx={{
              width: '100%',
              height: '4%', // Slightly reduced percentage for better proportion
              background: 'linear-gradient(to bottom, #e2e2e2, #d1d1d1)',
              borderBottomLeftRadius: '20px', // Increased for smoother look
              borderBottomRightRadius: '20px', // Increased for smoother look
              transformOrigin: 'top',
              transform: 'rotateX(-5deg)',
              boxShadow: '0 5px 30px rgba(0,0,0,0.2)', // Increased shadow spread
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MacBookContainer;
