import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MacBookContainer from './MacBookContainer';

const WhatIsMosaic: React.FC = () => {
  return (
    <Box sx={{ 
      padding: { xs: 2, md: 6 }, 
      paddingTop: { xs: 4, md: 8 }, 
      maxWidth: '1600px', // Increased to allow more space
      margin: '0 auto',
      overflow: 'hidden' // Prevent any potential overflow issues
    }}>
      {/* Title */}
      <Typography
        variant="h1"
        component="h1"
        gutterBottom
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 'bold',
          color: '#333',
          letterSpacing: '0.05em',
          textAlign: 'center',
          marginBottom: { xs: 4, md: 6 },
          fontSize: { xs: '2.5rem', md: '3.5rem' },
        }}
      >
        What is Mosaic?
      </Typography>

      {/* Content Grid */}
      <Grid container spacing={6} alignItems="center">
        {/* Left Side - Description and Features */}
        <Grid item xs={12} md={5}>
          <Box>
            <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', color: '#333', marginBottom: 3 }}>
              Analyze Your Conversations
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.3rem', lineHeight: 1.6, color: '#555', marginBottom: 4 }}>
              Mosaic is an innovative tool that analyzes your chat conversations and provides valuable insights into engagement, sentiment, and personality dynamics between you and your chat partner.
            </Typography>

            <Box component="ul" sx={{ 
              listStyle: 'none', 
              padding: 0, 
              margin: 0, 
              marginBottom: 4 
            }}>
              {[
                'Track emotional journey over time',
                'Gain personality insights',
                'Understand relationship dynamics',
                'Visualize engagement patterns',
              ].map((item, index) => (
                <Box component="li" key={index} sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  marginBottom: 2 
                }}>
                  <CheckCircleOutlineIcon color="primary" sx={{ fontSize: '2rem', marginRight: 2 }} />
                  <Typography sx={{ fontSize: '1.2rem', color: '#444', fontWeight: 500 }}>
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Typography variant="body2" sx={{ fontSize: '1.1rem', color: '#666', marginTop: 4 }}>
              <strong>Note:</strong> Mosaic is currently in beta, desktop-only, and designed for two-person chats. Try it out with our demo data or upload your own conversation for analysis.
            </Typography>
          </Box>
        </Grid>
        
        {/* Right Side - MacBook Container */}
        <Grid item xs={12} md={7}>
          <Box sx={{ 
            width: '100%', 
            height: '100%', 
            minHeight: '500px', // Ensure minimum height
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <MacBookContainer />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhatIsMosaic;
