import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  Link,
} from "@mui/material";
import React from "react";

interface TermsOfServiceProps {
  open?: boolean;
  onClose?: () => void;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({ open, onClose }) => {
  const content = (
    <>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontWeight: "bold", color: "primary.main" }}
      >
        Mosaic Terms of Service
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph>
        Effective Date: 10/21/24
      </Typography>

      <Divider sx={{ my: 2 }} />

      {[
        {
          title: "1. Acceptance of Terms",
          content: `By creating an account, accessing, or using Mosaic, you acknowledge that you have read, understood, and agree to these Terms. If you do not agree, you may not access or use the Service.`,
        },
        {
          title: "2. Modification of Terms",
          content: `We reserve the right to modify or update these Terms at any time. We will notify users of significant changes via email or a notice on our platform. Your continued use of the Service after such changes signifies your acceptance of the new terms.`,
        },
        {
          title: "3. Eligibility",
          content: `You must be at least 18 years old to use Mosaic, or the legal age of majority in your jurisdiction. By using the Service, you represent that you meet these age requirements and are capable of entering into a binding agreement.`,
        },
        {
          title: "4. Account Registration and Security",
          content: `To use certain features of the Service, you must register for an account. You agree to:
• Provide accurate, current, and complete information during registration.
• Maintain the security of your password and accept responsibility for all activities that occur under your account.
• Notify us immediately if you suspect any unauthorized use of your account.`,
        },
        {
          title: "5. Privacy Policy",
          content: `Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal information. By using the Service, you consent to the use of your information as outlined in the Privacy Policy.`,
        },
        // Add more sections as needed
      ].map((section, index) => (
        <Box key={index} sx={{ mb: 3 }}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              textAlign: "left",
            }}
          >
            {section.title}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ whiteSpace: "pre-line" }}
          >
            {section.content}
          </Typography>
          {index < 4 && <Divider sx={{ my: 2 }} />}
        </Box>
      ))}
    </>
  );

  if (open !== undefined && onClose) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="terms-of-service-modal"
        aria-describedby="terms-of-service-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          {content}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-start" }}>
            <Button onClick={onClose} variant="contained" color="primary">
              I Understand and Accept
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  return <Box sx={{ p: 4 }}>{content}</Box>;
};

export default TermsOfService;
