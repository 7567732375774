import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const data = [
  { weekStart: '2024-03-30', Emily: 6, John: 7 },
  { weekStart: '2024-04-20', Emily: 6, John: 5 },
  { weekStart: '2024-05-11', Emily: -8, John: -6 },
  { weekStart: '2024-06-08', Emily: 5, John: 8 },
  { weekStart: '2024-06-29', Emily: 7, John: 9 },
  { weekStart: '2024-07-20', Emily: 7, John: 8 },
];

const MoodStoryline = () => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        bgcolor: theme.palette.grey[50],
        borderRadius: 2,
        height: '100%',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mr: 1 }}>
          Mood Storyline
        </Typography>
        <InfoIcon
          sx={{
            fontSize: '1rem',
            color: 'text.secondary',
            cursor: 'help',
            '&:hover': {
              color: 'primary.main',
            },
          }}
        />
      </Box>
      <Box sx={{ height: 'calc(100% - 40px)' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey="weekStart"
              tickFormatter={(tickItem) => {
                const date = new Date(tickItem);
                return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
              }}
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
            />
            <YAxis
              domain={[-10, 10]}
              tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
            />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Emily" stroke={theme.palette.primary.main} strokeWidth={2} />
            <Line type="monotone" dataKey="John" stroke={theme.palette.secondary.main} strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      {/* Overlaid insights box */}
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          top: '45%',
          right: '10%',
          transform: 'translateY(-50%)',
          width: '35%',
          maxHeight: '75%',
          overflow: 'auto',
          p: 2,
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', fontSize: '0.875rem' }}>
          Week of May 10, 2024
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1, fontSize: '0.75rem' }}>
          Salient Events:
        </Typography>
        <ul style={{ paddingLeft: '10px', marginBottom: '10px', fontSize: '0.75rem' }}>
          <li>John and Emily get in a fight regarding FuFu</li>
        </ul>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mt: 2, mb: 1, fontSize: '0.75rem' }}>
          Top Quotes:
        </Typography>
        <Box sx={{ mb: 1, p: 1, bgcolor: theme.palette.primary.light, borderRadius: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: theme.palette.primary.main, fontSize: '0.75rem' }}>
            Emily
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            "Why are you mad?? All cats do that"
          </Typography>
        </Box>
        <Box sx={{ p: 1, bgcolor: theme.palette.secondary.light, borderRadius: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: theme.palette.secondary.main, fontSize: '0.75rem' }}>
            John
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
            "That's the third carpet"
          </Typography>
        </Box>
      </Paper>
    </Paper>
  );
};

export default MoodStoryline;