const SampleChatFile = (): string => {
  return `
  [8/1/24, 8:15:02 AM] Haylee: I can't believe you did this, Alex. This is the third time you've completely disregarded my feelings. Do you even care about this relationship at all? Because it sure doesn't feel like it!

[8/1/24, 8:25:15 AM] Alex: Morning, Haylee! You wouldn't believe the sunrise I just saw. The sky was this amazing pink and orange mix—so beautiful!

[8/1/24, 8:26:47 AM] Haylee: Not a big deal? How can you say that? You never take responsibility for anything. It's like you're not even trying!

[8/1/24, 8:31:32 AM] Alex: Oh, by the way, did you know that hummingbirds can flap their wings up to 80 times per second? Crazy, right? I saw one today and it was just mesmerizing!

[8/2/24, 9:02:11 AM] Haylee: You really think this is something we can just "move on" from? You're unbelievable. I'm so tired of being treated like this. I deserve better than someone who doesn't even care.

[8/2/24, 9:15:45 AM] Alex: Oh, hey, speaking of unbelievable, I saw this video of a dog skateboarding. Can you imagine? A dog! On a skateboard! 😂

[8/2/24, 9:18:06 AM] Haylee: It's not about arguing, it's about you acknowledging that you hurt me. But clearly, you don't care enough to do that. You're so detached, it's like talking to a wall!

[8/2/24, 9:22:37 AM] Alex: Detached? Nah, I'm just vibing, Haylee. You should try it sometime. Life's too short to stress, you know?

[8/4/24, 11:35:22 AM] Haylee: This silence is killing me. Are you even going to try and fix this? Or are you just going to keep ignoring me until I'm too exhausted to care anymore?

[8/4/24, 11:42:56 AM] Alex: Fix what? Oh, wait—guess what? I found this new cafe that sells the best croissants. We should totally go sometime!

[8/5/24, 10:10:30 AM] Haylee: "Sorry you're still upset" – that's all you have to say? This isn't just about being upset, Alex, it's about trust and respect. Do you even know what that means?

[8/5/24, 10:15:48 AM] Alex: Trust and respect? Absolutely! I totally respect your taste in movies, by the way. We should watch that rom-com you love this weekend, what do you think?

[8/5/24, 10:20:23 AM] Haylee: A break? That's your solution? Just walk away from everything instead of dealing with the issues? Unbelievable. You really don't care at all, do you?

[8/6/24, 8:45:37 AM] Alex: Walk away? Nah, I'd rather stroll through the park. The birds are singing, the sun's shining—can't we just enjoy the day?

[8/7/24, 12:00:05 PM] Haylee: Space won't solve anything. It's just another way for you to avoid responsibility. I'm so done with this, with you pretending like everything's fine when it's not!

[8/7/24, 12:10:14 PM] Alex: Everything is fine! Did you see the rainbow yesterday? It was so perfect. I wish I could have taken a picture, but you know, sometimes it's just better to enjoy the moment.

[8/9/24, 6:30:42 PM] Haylee: There you go again, making excuses. You're "not good at this kind of stuff," so what? That's a reason to just give up? To leave me feeling like I'm the only one who cares?

[8/9/24, 6:40:25 PM] Alex: Give up? Never! I'm all about living in the moment. Oh, speaking of which, there's a meteor shower tonight! Want to stay up and watch it with me?

[8/11/24, 9:00:11 AM] Haylee: Expecting too much? All I've ever wanted is for you to care, to show some emotion, to actually fight for this relationship. But clearly, that's too much to ask.

[8/11/24, 9:15:33 AM] Alex: Oh, I've got plenty of emotion—joy, excitement, love for life! You should try it, Haylee. Come on, let's go feed the ducks at the pond!

[8/13/24, 3:12:09 PM] Haylee: I know what I want, Alex. I want you to actually put in some effort, to show that you care instead of just saying it. But maybe that's never going to happen.

[8/13/24, 3:30:21 PM] Alex: I care a lot, Haylee! Like, I really care about this new recipe I found for chocolate cake. We should bake it together—what do you say?

[8/15/24, 8:20:45 AM] Haylee: It's not about expectations, Alex, it's about basic decency and respect. If you can't even give me that, then what's the point?

[8/15/24, 8:40:12 AM] Alex: The point is to enjoy life, Haylee! Let's go to the beach this weekend. Sand, sun, waves—it'll be amazing!

[8/17/24, 10:00:00 AM] Haylee: Fine. If space is what you want, take all the space you need. But don't expect me to wait around forever while you figure out how to care.

[8/17/24, 10:15:45 AM] Alex: Space? I was thinking more like a picnic under the stars. We could bring some wine and cheese, just relax and enjoy the night.

[8/18/24, 7:30:25 PM] Haylee:
Alex, I know we've both been dodging this, but we need to talk. I can't keep pretending everything's okay when I'm hurting this much. I miss you, but more than that, I miss feeling understood by you.

[8/18/24, 7:45:58 PM] Alex:
Haylee, I had no idea how much you've been hurting... I'm sorry I've been so distant. I always thought if I could keep things light, we wouldn't have to deal with all this heavy stuff. But now, I see it's not working, and I don't want you to feel alone. I care so much about you, I just don't always know how to show it.

[8/19/24, 9:10:12 AM] Haylee:
I understand you don't want to deal with the hard things, but that's part of being in a relationship, Alex. It's not all sunshine and rainbows. It's about being there for each other even when things aren't perfect. I need to feel like you're really here, with me.

[8/19/24, 9:25:33 AM] Alex:
You're right. I've been scared to dive into that because I'm not great with emotions, but I'm willing to try... for us. I don't want to lose you, Haylee. You're everything to me, and I'm sorry if I've made you feel otherwise.

[8/20/24, 8:15:47 PM] Haylee:
I appreciate that, Alex. I really do. But it's going to take more than words. I need to feel like you're actually putting effort into this, like you're as invested as I am.

[8/20/24, 8:45:02 PM] Alex:
I know. And you deserve that. I've been thinking about what you said, and I want to start doing better. Can we take it step by step? I don't want to rush, but I also don't want to keep making the same mistakes.

[8/22/24, 5:30:00 PM] Haylee:
Of course, Alex. That's all I've ever wanted—to work through things together. I'm not asking for perfection, just for you to try and meet me halfway.

[8/22/24, 5:45:55 PM] Alex:
I'll do whatever it takes to be there for you. I've been scared of messing up, but I think I realized today that I mess up more by not trying. You deserve someone who's all in, and I want to be that person.

[8/24/24, 10:10:33 AM] Haylee:
It means so much that you're saying this. I've felt so disconnected for so long, and it's exhausting trying to hold everything together. But I can't do it alone anymore, Alex.

[8/24/24, 10:25:00 AM] Alex:
You don't have to, Haylee. You're not alone in this. I've been selfish and naive, thinking it was easier to stay on the surface, but I see now that the real beauty is in the depth of things. I want to dive deep with you.

[8/26/24, 6:00:27 PM] Haylee:
That's all I've ever wanted, for you to be here with me, emotionally. We can't keep avoiding the difficult conversations, but I believe if we try together, we can get through it. Do you really believe we can work things out?

[8/26/24, 6:15:42 PM] Alex:
I do. I've been reflecting a lot, and I want to rebuild that trust. I know it won't happen overnight, but I'm committed. I've been scared of losing you this whole time, and that fear made me pull away. But I realize now, pulling away is exactly what pushed you away.

[8/27/24, 12:00:45 PM] Haylee:
Thank you for saying that. I know we still have a lot to figure out, but hearing you acknowledge how I've been feeling... it really matters. I'm willing to keep trying if you are.

[8/27/24, 12:30:11 PM] Alex:
Absolutely. I know actions speak louder than words, and I'll prove it. I'm going to start showing up for you the way I should have all along.

[9/1/24, 11:00:33 AM] Haylee:
I've noticed you've been more present lately, and I want you to know I appreciate it. It's made me feel more secure, like we're actually in this together.

[9/1/24, 11:15:47 AM] Alex:
That means a lot to hear. I've been working on being more mindful about how I respond to things, especially when it comes to you. I want to keep growing with you, Haylee. We've got something special, and I don't want to lose that.

[9/3/24, 7:45:22 PM] Haylee:
You're right, we do. I'm realizing it's not about being perfect, it's about making the effort and being honest with each other. And I feel like we're finally doing that.

[9/3/24, 8:00:11 PM] Alex:
I feel it too. It's been tough facing some of these emotions, but I see now that avoiding them wasn't helping. I want to be the partner you deserve, and that means facing the hard stuff head-on, together.

[9/10/24, 10:45:00 AM] Haylee:
I'm starting to feel more hopeful about us. We still have things to work on, but I feel like we're on the right path. Thank you for sticking with me through this, even when things got tough.

[9/10/24, 11:00:33 AM] Alex:
Of course, Haylee. I'm not going anywhere. I'm in this for the long haul, and I'll keep putting in the work because you're worth it. We're worth it.

[9/12/24, 9:00:12 AM] Haylee:
We really are. I love you, Alex. I just needed to know you were in this with me, and now that I feel that, I'm ready to keep building this life together.

[9/12/24, 9:15:45 AM] Alex:
I love you too, Haylee. More than anything. Let's keep moving forward, one step at a time, and make this the relationship we both deserve.

  `;
};

export default SampleChatFile;
