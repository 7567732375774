import { PersonalityType, PersonalityTheme } from './personalityThemes/types';
import { personalityThemes } from './personalityThemes/themes';

// Global personality types
export const validPersonalityTypes = ['ENTP', 'none'] as const;

export type GlobalPersonalityType = typeof validPersonalityTypes[number];

// For testing purposes, we'll set a fixed value
// In the future, this will be determined by external data
const CURRENT_PERSONALITY: GlobalPersonalityType = 'none'; // or 'none' for testing

// Helper function to get theme for a personality type
export const getPersonalityTheme = (type: GlobalPersonalityType = CURRENT_PERSONALITY): PersonalityTheme => {
  return personalityThemes[type] || personalityThemes.none;
};

// Helper function to validate personality type
export const isValidPersonalityType = (type: string): type is GlobalPersonalityType => {
  return validPersonalityTypes.includes(type as GlobalPersonalityType);
};

// Function to get current personality type
export const getCurrentPersonality = (): GlobalPersonalityType => {
  return CURRENT_PERSONALITY;
};
