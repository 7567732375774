import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useCallback } from 'react'
import WordCloudComponent from 'react-d3-cloud'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { useS3Fetcher } from '../utils/fetcher'
import { getUserName } from '../utils/general'
import { getTopWords, WordCloudResult, WordItem } from '../utils/wordCloud'

const WordCloud: React.FC = React.memo(() => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { users } = useGeneralInfoContext()

  const { data: wordCloudResult, error } = useS3Fetcher<WordCloudResult>(
    getTopWords,
    'chat/:hash:/wordcloud.json'
  )

  const fontSizeMapper = useCallback((word: WordItem) => {
    return Math.max(Math.log(word.value * 100 + 1), 0.5) * 40 + 5
  }, [])

  const colorMapper = (word: WordItem, index: number) => {
    const colors = [theme.palette.primary.main, theme.palette.secondary.main]
    return colors[index % colors.length]
  }

  if (!users || !wordCloudResult) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body1">Loading word cloud data...</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{ mb: 2, fontWeight: 'bold' }}
      >
        Word Cloud Comparison
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        {wordCloudResult?.people.map((person, idx) => (
          <Paper
            key={person.name}
            elevation={3}
            sx={{
              p: 2,
              bgcolor: theme.palette.grey[50],
              borderRadius: 2,
              mb: isMobile ? 2 : 0,
              minWidth: isMobile ? '100%' : '48%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h6"
              component="h3"
              sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}
            >
              {getUserName(users, person.name)}'s Top Words
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                width: '100%',
                height: theme.spacing(50),
                bgcolor: theme.palette.background.paper,
                borderRadius: 2,
                p: 1,
              }}
            >
              <WordCloudComponent
                data={person.topWords}
                fontSize={fontSizeMapper}
                rotate={0}
                padding={2}
                fill={(word: WordItem, index: number) =>
                  colorMapper(word, index)
                }
              />
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  )
})

export default WordCloud
