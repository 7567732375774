import { Box, CircularProgress } from '@mui/material'
import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WelcomePopup from './Popups/WelcomePopup'
import CustomDrawer from './UserDashboard/CustomDrawer'
import Footer from './UserDashboard/Footer'
import Header from './UserDashboard/Header'
import MainContent from './UserDashboard/MainContent'
import {
  getCurrentPersonality,
  getPersonalityTheme,
} from './UserDashboard/components/GlobalPersonalityType'

interface Chat {
  id: string
  name: string
  date: string
  category: string // Add this line
}

const UserDashboard: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([])
  const [user, setUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [welcomePopupOpen, setWelcomePopupOpen] = useState(false)
  const navigate = useNavigate()

  const loadUserData = useCallback(async () => {
    try {
      const { tokens } = await fetchAuthSession()
      if (!tokens || !tokens.accessToken) {
        console.log('No access token available')
        return
      }
      setUser(tokens.accessToken)

      // Updated mock chats with categories
      setChats([
        {
          id: '1',
          name: 'Chat 1',
          date: new Date().toISOString(),
          category: 'nature',
        },
        {
          id: '2',
          name: 'Chat 2',
          date: new Date().toISOString(),
          category: 'fantasy',
        },
        {
          id: '3',
          name: 'Chat 3',
          date: new Date().toISOString(),
          category: 'sci-fi',
        },
      ])
    } catch (error) {
      console.error('Error loading user data:', error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    loadUserData()
  }, [loadUserData])

  const handleUploadSuccess = (
    chatId: string,
    category: string = 'default'
  ) => {
    const newChat: Chat = {
      id: chatId,
      name: `Chat ${chats.length + 1}`,
      date: new Date().toISOString(),
      category: category,
    }
    setChats([...chats, newChat])
  }

  const handleSignOut = async () => {
    try {
      await signOut()
      localStorage.setItem('showWelcomePopup', 'true')
      navigate('/')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setDrawerOpen(open)
    },
    []
  )

  // Add this line to get the default theme
  const personalityTheme = getPersonalityTheme()
  const personalityType = getCurrentPersonality() // Add this line

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header
        user={user}
        toggleDrawer={toggleDrawer}
        handleSignOut={handleSignOut}
        personalityTheme={personalityTheme}
      />
      <CustomDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <MainContent
        chats={chats}
        handleUploadSuccess={handleUploadSuccess}
        personalityType={personalityType}
      />
      <Footer />
      <WelcomePopup
        open={welcomePopupOpen}
        onClose={() => setWelcomePopupOpen(false)}
      />
    </Box>
  )
}

export default UserDashboard
