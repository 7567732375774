import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import React from 'react'

const ExportInstructions: React.FC = () => {
  const instructions = [
    {
      title: 'How do I export my WhatsApp chat from my phone (easiest)',
      steps: [
        'Go to Settings ⚙️',
        'Select "Chats" 💬',
        'Click on "Export chat"',
        'Select the chat you wish to export',
        'Click on "Without Media". If you are on a laptop this will save to downloads.',
        'Click the Upload File button above (you can also drag and drop files onto the button from mail/messages) and select your file!',
      ],
    },
    {
      title: 'How do I export my WhatsApp chat (Others)?',
      steps: [
        'Open the chat you wish to export',
        'Tap More options > More > Export chat',
        'Tap without media and save to downloads. For mobile devices you can message, email, or airdrop the file to yourself.',
        'Click the Upload File button above (can also drag and drop files into the button from mail/messages) and select your file!',
      ],
    },
    {
      title: 'How do I export my iMessage chat (Mac)?',
      steps: [
        'READ THE WHOLE THING BEFORE UPLOADING CHAT',
        'Copy this file path: ~/Library/Messages and click on the upload file button.',
        'Hold Command + Shift + G on your keyboard, and paste ~/Library/Messages into the search bar',
        'Select the first option that comes up { > Library > Messages }',
        'Select the chat.db file and wait for the pop up menu (this can take a while depending on how much you use imessage, sometimes over a minute)',
        'Finally, in the chat pop up menu, select the chat you wish to analyze',
        'Please note that the file includes your entire imessage history, it is once the file is finished processing that you can select your individual chats'
      ],
    },
    {
      title: 'How do I export my Facebook chat?',
      steps: [
        'Open the Messenger app and go to Settings ⚙️',
        'Scroll down and select See more in Accounts Center',
        'Select "Your information and permissions", then Download Your Information, pressing the Download or transfer information button, choosing your account, and selecting Some of your Information',
        'Search Messages, select Download to Device and select the chat history you wish to export. By default, you get one year of messages, but you can select "All Time" for completeness.',
        'VERY IMPORTANT: Click on "Format", and select "JSON", and "Low Quality". Once ready, input your email and receive your file (in a few hours)',
        'Mow the lawn, watch a series and wait for that email notification!',
        'Download the file from your instagram/facebook app and transfer it to your computer (email, messages, airdrop etc.)',
        'Click the Upload File button and select your file, or drag and drop it above!',
      ],
    },
    {
      title: 'How do I export my Instagram chat?',
      steps: [
        'Open the Instagram app and click on your profile, then navigate to Settings and Activity (the three bars in the top right of your profile page)',
        'Select Your Activity',
        'Scroll all the way down and click Download Your Information, pressing the Download or transfer information button, choosing your account, and selecting Some of your Information',
        'Search Messages, select Download to Device and select the chat history you wish to export. By default, you get one year of messages, but you can select "All Time" for completeness.',
        'VERY IMPORTANT: Click on "Format", and select "JSON", and "Low Quality". Once ready, input your email and receive your file (in a few hours)',
        'Mow the lawn, watch a series and wait for that email notification!',
        'Download the file from your instagram/facebook app and transfer it to your computer (email, messages, airdrop etc.)',
        'Click the Upload File button and select your file, or drag and drop it above!',
      ],
    },
    {
      title: 'How do I export my iMessage chat (iPhone)?',
      steps: ['Coming soon.....'],
    },
    {
      title: 'How do I export my Android chat?',
      steps: [
        'https://www.wikihow.com/Copy-an-Entire-Text-Conversation-on-Android',
      ],
    },
  ]

  return (
    <Box sx={{ maxWidth: '600px', margin: '20px auto' }}>
      {instructions.map((instruction, index) => (
        <Accordion
          key={index}
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:not(:last-child)': {
              marginBottom: '12px',
            },
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#007AFF' }} />}
            aria-controls={`panel${index + 1}a-content`}
            id={`panel${index + 1}a-header`}
            sx={{
              backgroundColor: '#f7f7f7',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
              '&.Mui-expanded': {
                minHeight: 48,
              },
              '& .MuiAccordionSummary-content': {
                margin: '12px 0',
              },
            }}
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}
            >
              {instruction.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: '0 16px 16px', backgroundColor: 'white' }}
          >
            <List>
              {instruction.steps.map((step, stepIndex) => (
                <React.Fragment key={stepIndex}>
                  <ListItem sx={{ padding: '8px 0' }}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          sx={{ fontSize: '0.875rem', color: '#555' }}
                        >
                          <strong>Step {stepIndex + 1}:</strong> {step}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {stepIndex < instruction.steps.length - 1 && (
                    <Divider variant="middle" component="li" />
                  )}
                </React.Fragment>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default ExportInstructions
