import {
  fetchAuthSession,
  fetchUserAttributes,
  updateUserAttributes,
} from 'aws-amplify/auth'

export async function updatePreferredUsername(username: string) {
  try {
    // Explicitly check auth session first
    const session = await fetchAuthSession()
    if (!session.tokens) {
      console.error('No tokens found in session')
      return false
    }

    console.log('Access token:', session.tokens.accessToken)

    const attributes = {
      userAttributes: {
        preferred_username: username,
      },
    }

    // Update the user attributes
    await updateUserAttributes(attributes)

    // Fetch and print the updated user attributes
    const currentAttributes = await fetchUserAttributes()
    console.log('Updated user attributes:', currentAttributes)

    return true
  } catch (error) {
    console.error('Error updating username:', error)
    return false
  }
}

export async function getPreferredUsername() {
  const currentAttributes = await fetchUserAttributes()
  return currentAttributes.preferred_username
}
