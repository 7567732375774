import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { signIn, signInWithRedirect } from 'aws-amplify/auth'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SignIn: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)
    setIsLoading(true)

    try {
      console.log('Attempting sign in with:', { username }) // Don't log passwords
      const { isSignedIn, nextStep } = await signIn({ username, password })
      console.log('Sign in result:', { isSignedIn, nextStep })

      if (isSignedIn) {
        navigate('/user-dashboard')
      } else {
        console.log('Sign in failed. Next step:', nextStep)
        setError(`Sign in failed. Next step: ${JSON.stringify(nextStep)}`)
      }
    } catch (error) {
      console.error('Sign in error:', error)
      if (error instanceof Error) {
        setError(`Sign in failed: ${error.name} - ${error.message}`)
      } else {
        setError('An unknown error occurred during sign in.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      await signInWithRedirect({
        provider: 'Google',
      })
    } catch (error) {
      console.error('Google sign-in error:', error)
      setError(
        error instanceof Error ? error.message : 'Failed to sign in with Google'
      )
    }
  }

  const handleFacebookSignIn = async () => {
    try {
      await signInWithRedirect({
        provider: 'Facebook',
      })
    } catch (error) {
      console.error('Facebook sign-in error:', error)
      setError(
        error instanceof Error
          ? error.message
          : 'Failed to sign in with Facebook'
      )
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleSignIn}
            sx={{ mt: 2, mb: 1 }}
          >
            Continue with Google
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<FacebookIcon />}
            onClick={handleFacebookSignIn}
            sx={{ mb: 2 }}
          >
            Continue with Facebook
          </Button>
          <Divider sx={{ mb: 2 }}>or</Divider>
          <Link href="/signup" variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

export default SignIn
