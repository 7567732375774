import { Box, Container, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import PrivacyPolicyModal from "../Documents/PrivacyPolicy";
import TermsOfServiceModal from "../Documents/TermsOfService";

const Footer: React.FC = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const handleOpenPrivacyModal = () => setIsPrivacyModalOpen(true);
  const handleClosePrivacyModal = () => setIsPrivacyModalOpen(false);
  const handleOpenTermsModal = () => setIsTermsModalOpen(true);
  const handleCloseTermsModal = () => setIsTermsModalOpen(false);

  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        color: "text.secondary",
        py: 2,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
            © 2024 Mosaic. All rights reserved.
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Link
              href="mailto:contactus.mosaic@gmail.com"
              color="inherit"
              underline="hover"
              sx={{ fontSize: "0.875rem" }}
            >
              Contact Us
            </Link>
            <Link
              href="https://discord.gg/qPTWCdeUgQ"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              underline="hover"
              sx={{ fontSize: "0.875rem" }}
            >
              Join Discord
            </Link>
            <Link
              component="button"
              color="inherit"
              underline="hover"
              onClick={handleOpenPrivacyModal}
              sx={{ fontSize: "0.875rem" }}
            >
              Privacy Policy
            </Link>
            <Link
              component="button"
              color="inherit"
              underline="hover"
              onClick={handleOpenTermsModal}
              sx={{ fontSize: "0.875rem" }}
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
      <PrivacyPolicyModal
        open={isPrivacyModalOpen}
        onClose={handleClosePrivacyModal}
      />
      <TermsOfServiceModal
        open={isTermsModalOpen}
        onClose={handleCloseTermsModal}
      />
    </Box>
  );
};

export default Footer;
