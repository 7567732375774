import { PersonalityTheme, PersonalityType } from "./types";

export const personalityThemes: Record<PersonalityType, PersonalityTheme> = {
  none: {
    background: {
      gradient: "linear-gradient(135deg, #e0f2ff, #e6e0ff)",
    },
    tiles: {
      backgroundColor: "white",
      titleColor: "#666",
      numberGradients: {
        low: "linear-gradient(45deg, #94A3B8, #64748B)",         // Cool gray - starting out
        medium: "linear-gradient(45deg, #60A5FA, #3B82F6)",      // Blue - making progress
        high: "linear-gradient(45deg, #34D399, #10B981)",        // Green - doing well
        veryHigh: "linear-gradient(45deg, #FBBF24, #F59E0B)",    // Gold - excellent
        exceptional: "linear-gradient(45deg, #F43F5E, #E11D48)",  // Red - outstanding
      },
      fontFamily: "inherit",
    },
    typography: {
      titleGradient: "linear-gradient(45deg, #3B82F6, #00C4FF, #60A5FA)",
    },
  },
  ENTP: {
    background: {
      gradient: "linear-gradient(45deg, #4facfe 0%, #00f2fe 33%, #a8edea 66%, #fed6e3 100%)",
    },
    tiles: {
      backgroundColor: "white",
      titleColor: "#666",
      numberGradients: {
        low: "currentColor",
        medium: "currentColor",
        high: "currentColor",
        veryHigh: "currentColor",
        exceptional: "currentColor",
      },
      fontFamily: "'JetBrains Mono', monospace", // Keeping the tech-focused font
    },
    typography: {
      titleGradient: "linear-gradient(90deg, #40E0D0, #FF851B)", // Keeping the vibrant gradient only for the title
    },
  },
};
