import { ChatUser } from './types'

export function formatDate(date: Date) {
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function getUserName(users: ChatUser[], username: string) {
  return users.find((user) => user.username === username)?.name || username
}
