import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Tooltip as MuiTooltip,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import React from 'react'
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { EngagementData, processEngagementData } from '../utils/engagementLogic'
import { useS3Fetcher } from '../utils/fetcher'

const Engagement: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { users } = useGeneralInfoContext()
  const { data: engagementData } = useS3Fetcher<EngagementData>(
    processEngagementData,
    `chat/:hash:/engagement.json`
  )
  if (!engagementData || !users) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body1">Loading chart data...</Typography>
      </Box>
    )
  }
  const { chartData } = engagementData

  if (chartData.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body1">
          No data available for the chart.
        </Typography>
      </Box>
    )
  }

  const [user1, user2] = users
  const user1Color = theme.palette.primary.main
  const user2Color = theme.palette.secondary.main

  const formatXAxis = (tickItem: string) => {
    return format(parseISO(tickItem), 'MMM d, yyyy')
  }

  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: theme.palette.grey[50],
          borderRadius: 2,
          mb: 4,
          height: theme.spacing(54),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontWeight: 'bold', mr: 1 }}
          >
            Engagement
          </Typography>
          <MuiTooltip
            title="This chart tracks the number of words spoken by each person over time. It provides insights into conversation patterns, showing periods of high engagement and potential imbalances in communication."
            arrow
          >
            <InfoIcon
              sx={{
                fontSize: '1rem',
                color: 'text.secondary',
                cursor: 'help',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
              aria-label="Engagement Chart Information"
            />
          </MuiTooltip>
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <XAxis
                dataKey="weekStart"
                tickFormatter={formatXAxis}
                interval={(isMobile ? 2 : 1) * Math.ceil(chartData.length / 10)}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <YAxis
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                }}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey={(data) => data.numWords[user1.username]}
                name={user1.name}
                stroke={user1Color}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey={(data) => data.numWords[user2.username]}
                name={user2.name}
                stroke={user2Color}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Box>
  )
}

export default Engagement
