import { Box, Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { amIAuthedOwner } from '../utils/hashAuthentication'
import { useShareFunctionality } from './ShareIconButton' // Adjust the import path as needed

const DiscordAndShop: React.FC = () => {
  const navigate = useNavigate()
  const { isLoading, shareAction, ShareModal } = useShareFunctionality()
  const { hash, file, setHash, setToken } = useGeneralInfoContext()
  const [amIOwner, setAmIOwner] = useState(Boolean(file))
  useEffect(() => {
    if (file) {
      setAmIOwner(true)
    } else {
      amIAuthedOwner(hash || '').then(setAmIOwner)
    }
  }, [hash])

  const handleClick = () => {
    if (!amIOwner) {
      setHash('')
      setToken('')
      navigate('/')
    } else {
      shareAction(hash, file)
    }
  }

  const handleShopClick = () => {
    navigate('/shop')
  }

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button
        onClick={handleShopClick}
        variant="contained"
        color="secondary"
        sx={{
          margin: '20px 0',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#9c27b0',
          },
        }}
      >
        Mosaic Shop
      </Button>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        disabled={isLoading && amIOwner}
        sx={{
          margin: '20px 0',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#45a049',
          },
        }}
      >
        {!amIOwner ? (
          'Analyze another chat'
        ) : isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Share your dashboard'
        )}
      </Button>
      {amIOwner && <ShareModal />}
    </Box>
  )
}

export default DiscordAndShop
