import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { getToken, getTokenThroughAuth } from '../utils/hashAuthentication'

export const useShareFunctionality = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [error, setError] = useState("");
  const theme = useTheme();

  const handleShare = async (hash?: string | null, file?: File | null) => {
    if (!hash) {
      return
    }
    setIsLoading(true)
    setError('')
    try {
      let token = ''
      if (!file) {
        token = await getTokenThroughAuth(hash, 60 * 60 * 24)
      } else {
        token = await getToken(hash, file, 60 * 60 * 24)
      }
      const baseUrl = `${window.location.protocol}//${window.location.host}`
      setShareUrl(`${baseUrl}/?hash=${hash}&token=${token}`)
      setIsModalOpen(true)
    } catch (err) {
      setError("Unauthorized: Unable to generate share link");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // This is the exportable action function
  const shareAction = (hash?: string | null, file?: File | null) => {
    handleShare(hash, file);
  };

  const ShareModal = () => (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxWidth: "90%",
          bgcolor: theme.palette.background.paper,
          borderRadius: 2,
          p: 4,
        }}
      >
        {error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Share Link
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              gutterBottom
              sx={{ fontWeight: "bold", color: theme.palette.error.main }}
            >
              WARNING: SENSITIVE DATA
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              This feature is in beta and does not currently mask any aspects of
              your dashboard. Any and all information that you see in this
              dashboard will be viewable by the person who uses the link.
              <Typography component="p" sx={{ mt: 1, fontWeight: "bold" }}>
                Note: For security purposes and to prevent unwanted
                dissemination of your information, the share link will only work
                for one day.
              </Typography>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography
                component="a"
                href={shareUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {shareUrl}
              </Typography>
              <IconButton onClick={handleCopyLink} size="small" sx={{ ml: 1 }}>
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </>
        )}
      </Paper>
    </Modal>
  );

  return {
    isLoading,
    shareAction,
    ShareModal,
  };
};

const ShareIconButton: React.FC = () => {
  const { isLoading, shareAction, ShareModal } = useShareFunctionality();

  const { hash, file } = useGeneralInfoContext();
  return (
    <>
      <Tooltip title="Share">
        <Typography
          component="span"
          onClick={() => shareAction(hash, file)}
          color="primary"
          aria-label="share"
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : <ShareIcon />}
        </Typography>
      </Tooltip>
      <ShareModal />
    </>
  );
};

export default ShareIconButton;
