import { Box, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useS3Fetcher } from '../utils/fetcher'
import { calculateAllMetrics } from '../utils/metrics'

const MetricSwitcher: React.FC = () => {
  const [currentMetricIndex, setCurrentMetricIndex] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const { data: raw_metrics } = useS3Fetcher<string[]>(
    calculateAllMetrics,
    `chat/:hash:/metrics.json`
  )
  const metrics = useMemo(() => raw_metrics || [], [raw_metrics])

  useEffect(() => {
    if (metrics.length > 0 && !isExpanded) {
      const intervalId = setInterval(() => {
        setCurrentMetricIndex((prevIndex) => (prevIndex + 1) % metrics.length)
      }, 5000)
      return () => clearInterval(intervalId)
    }
  }, [metrics, isExpanded])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false)
      }
    }

    if (isExpanded) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isExpanded])

  if (metrics.length === 0) {
    return <div>Loading metrics...</div>
  }

  return (
    <Box
      ref={dropdownRef}
      onClick={() => setIsExpanded(!isExpanded)}
      sx={{ cursor: 'pointer', position: 'relative' }}
    >
      {isExpanded ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 10,
            backgroundColor: 'white',
            boxShadow: 3,
            padding: 2,
            maxHeight: '200px',
            overflowY: 'auto',
            width: '500px',
          }}
        >
          {metrics.map((metric, index) => (
            <Typography
              key={index}
              variant="subtitle1"
              component="div"
              sx={{ fontSize: 'inherit', textAlign: 'center', marginBottom: 1 }}
            >
              {metric}
            </Typography>
          ))}
        </Box>
      ) : (
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ fontSize: 'inherit', textAlign: 'center' }}
        >
          {metrics[currentMetricIndex]}
        </Typography>
      )}
    </Box>
  )
}

export default MetricSwitcher
