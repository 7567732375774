import {
  Box,
  IconButton,
  Paper,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { FaBars } from "react-icons/fa";
import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MajorEventType } from "../../../utils/sentimentAnalysis";
import { calculateWeightedSentiment } from "./weightedSentiment"; // Import the existing module
import { getWeekStart } from "./dateUtils";
interface GlobalSentimentChartProps {
  sentimentData: Record<string, number>;
  majorEvents?: MajorEventType[];
  numChats: number;
  chatNames: Record<string, string>;
}

interface ChartDataPoint {
  weekStart: string;
  sentiment: number | null;
  timestamp: number;
}

const GlobalSentimentChart: React.FC<GlobalSentimentChartProps> = ({
  sentimentData,
  majorEvents = [],
  numChats,
  chatNames,
}) => {
  // console.log('Major Events received:', majorEvents)
  // console.log('Sentiment Data received:', sentimentData)
  // console.log('Number of chats:', numChats)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [dateRange, setDateRange] = React.useState<[number, number]>([0, 100]);
  const [isEventsPanelOpen, setIsEventsPanelOpen] = useState<boolean>(false);

  // Move weightedSentimentData calculation before it's used
  const transformedMajorEvents = useMemo(() => {
    // Add debug logging
    console.log("Original major events:", majorEvents);
    const filteredEvents = majorEvents.filter(
      (event) => event.major_score >= 8
    );
    console.log("Filtered major events:", filteredEvents);
    return {
      global: majorEvents,
    };
  }, [majorEvents]);

  const weightedSentimentData = useMemo(() => {
    return calculateWeightedSentiment(
      { global: sentimentData },
      transformedMajorEvents
    );
  }, [sentimentData, transformedMajorEvents]);

  // Now we can use weightedSentimentData in chartData
  const chartData: ChartDataPoint[] = useMemo(() => {
    // First standardize all dates to week starts
    const uniqueWeekData = new Map<string, number>();
    
    Object.entries(weightedSentimentData).forEach(([date, sentiment]) => {
      const weekStart = getWeekStart(date);
      uniqueWeekData.set(weekStart, sentiment);
    });

    return Array.from(uniqueWeekData.entries())
      .map(([weekStart, sentiment]) => ({
        weekStart,
        sentiment,
        timestamp: new Date(weekStart).getTime(),
      }))
      .sort((a, b) => a.timestamp - b.timestamp);
  }, [weightedSentimentData]);

  // Insert nulls for gaps larger than two weeks to create discontinuities
  const processedChartData: ChartDataPoint[] = useMemo(() => {
    const MAX_GAP = 14 * 24 * 60 * 60 * 1000; // Two weeks in milliseconds
    const newData: ChartDataPoint[] = [];

    for (let i = 0; i < chartData.length; i++) {
      const currentPoint = chartData[i];
      newData.push(currentPoint);

      if (i < chartData.length - 1) {
        const nextPoint = chartData[i + 1];
        const gap = nextPoint.timestamp - currentPoint.timestamp;

        if (gap > MAX_GAP) {
          newData.push({
            weekStart: new Date(
              currentPoint.timestamp + MAX_GAP / 2
            ).toISOString(),
            sentiment: null,
            timestamp: currentPoint.timestamp + MAX_GAP / 2,
          });
        }
      }
    }

    return newData;
  }, [chartData]);

  // Get the actual data points (excluding nulls) for date range calculations
  const dateRangeData = useMemo(() => {
    return Object.entries(sentimentData)
      .map(([weekStart, _]) => ({
        timestamp: new Date(weekStart).getTime(),
      }))
      .sort((a, b) => a.timestamp - b.timestamp);
  }, [sentimentData]);

  const minDate = dateRangeData.length > 0 ? dateRangeData[0].timestamp : 0;
  const maxDate =
    dateRangeData.length > 0
      ? dateRangeData[dateRangeData.length - 1].timestamp
      : 0;

  // Filter the chart data based on the date range
  const filteredChartData = useMemo(() => {
    return processedChartData.filter((data) => {
      const percentage =
        ((data.timestamp - minDate) / (maxDate - minDate)) * 100;
      return percentage >= dateRange[0] && percentage <= dateRange[1];
    });
  }, [processedChartData, dateRange, minDate, maxDate]);

  const handleDateRangeChange = (event: Event, newValue: number | number[]) => {
    setDateRange(newValue as [number, number]);
  };

  const valuetext = (value: number) => {
    if (dateRangeData.length === 0) return "";
    try {
      const timestamp = minDate + ((maxDate - minDate) * value) / 100;
      return format(new Date(timestamp), "MMM d, yyyy");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  // Function to get top 10 events within date range
  const getVisibleEvents = () => {
    // Get events within the current date range
    const eventsInRange = majorEvents.filter((event) => {
      const eventDate = new Date(event.timestamp_range.start).getTime();
      const percentage = ((eventDate - minDate) / (maxDate - minDate)) * 100;
      return percentage >= dateRange[0] && percentage <= dateRange[1];
    });

    // Calculate mean sentiment for the visible date range
    const visibleChartData = processedChartData.filter((data) => {
      if (data.sentiment === null) return false;
      const percentage =
        ((data.timestamp - minDate) / (maxDate - minDate)) * 100;
      return percentage >= dateRange[0] && percentage <= dateRange[1];
    });

    const meanSentiment =
      visibleChartData.length > 0
        ? visibleChartData.reduce(
            (sum, data) => sum + (data.sentiment || 0),
            0
          ) / visibleChartData.length
        : 0;

    // Find sentiment values for each event
    const eventsWithSentiment = eventsInRange
      .map((event) => {
        const eventDate = new Date(event.timestamp_range.start).getTime();

        // Find the week that contains this event
        const containingWeek = processedChartData.find((dataPoint) => {
          if (dataPoint.sentiment === null) return false;
          const weekStart = new Date(dataPoint.weekStart).getTime();
          const weekEnd = weekStart + 7 * 24 * 60 * 60 * 1000; // One week later
          return eventDate >= weekStart && eventDate < weekEnd;
        });

        if (!containingWeek) return null;

        return {
          ...event,
          deviationFromMean: Math.abs(
            (containingWeek.sentiment || 0) - meanSentiment
          ),
          actualSentiment: containingWeek.sentiment,
          weekStart: containingWeek.weekStart,
        };
      })
      .filter((event): event is NonNullable<typeof event> => event !== null);

    // Sort by deviation from mean (highest deviation first) and take top 10
    const sortedEvents = [...eventsWithSentiment]
      .sort((a, b) => (b.deviationFromMean || 0) - (a.deviationFromMean || 0))
      .slice(0, 10);

    // Sort chronologically for display
    return sortedEvents.sort(
      (a, b) =>
        new Date(a.timestamp_range.start).getTime() -
        new Date(b.timestamp_range.start).getTime()
    );
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        bgcolor: theme.palette.grey[50],
        borderRadius: 2,
        height: theme.spacing(50),
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Add container for title and toggle button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
          position: "relative",
        }}
      >
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
          Global Sentiment Chart
        </Typography>
        <IconButton
          onClick={() => setIsEventsPanelOpen(!isEventsPanelOpen)}
          size="small"
          sx={{
            position: "absolute",
            right: 0,
          }}
        >
          <FaBars />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          gap: 2,
          height: "calc(100% - 60px)", // Adjust for header
        }}
      >
        {/* Chart Section - Adjust width based on panel state */}
        <Box
          sx={{
            flexGrow: 1,
            width: isEventsPanelOpen ? "70%" : "100%",
            transition: "width 0.3s ease",
          }}
        >
          <ResponsiveContainer width="100%" height="85%">
            <LineChart
              data={filteredChartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <XAxis
                dataKey="weekStart"
                tickFormatter={(tickItem) =>
                  tickItem ? format(new Date(tickItem), "MMM d, yyyy") : ""
                }
                interval={(isMobile ? 2 : 1) * Math.ceil(chartData.length / 10)}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <YAxis
                domain={[-10, 10]}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <Tooltip
                formatter={(value: number) => [
                  value.toFixed(2),
                  "Average Sentiment",
                ]}
                labelFormatter={(label) =>
                  format(new Date(label), "MMMM d, yyyy")
                }
              />

              {getVisibleEvents().map((event, index) => (
                <ReferenceLine
                  key={index}
                  x={event.weekStart}
                  stroke={theme.palette.secondary.main}
                  strokeDasharray="3 3"
                  label={{
                    value: event.event,
                    position: "insideTopRight",
                    fill: theme.palette.text.secondary,
                    fontSize: 10,
                    angle: -45,
                  }}
                />
              ))}

              <Line
                type="monotone"
                dataKey="sentiment"
                name="Sentiment"
                stroke={theme.palette.primary.main}
                strokeWidth={2}
                dot={false}
                connectNulls={false}
              />
            </LineChart>
          </ResponsiveContainer>

          <Box sx={{ px: 3, pt: 2, mt: -2 }}>
            <Slider
              value={dateRange}
              onChange={handleDateRangeChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valuetext}
              getAriaValueText={valuetext}
              sx={{
                "& .MuiSlider-valueLabel": {
                  fontSize: "0.75rem",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                color: theme.palette.text.secondary,
                fontSize: "0.75rem",
              }}
            >
              <span>{valuetext(dateRange[0])}</span>
              <span>{valuetext(dateRange[1])}</span>
            </Box>
          </Box>
        </Box>

        {/* Events Section - Modified for collapse/expand */}
        <Box
          sx={{
            width: isEventsPanelOpen ? "30%" : "0%",
            overflow: "hidden",
            transition: "width 0.3s ease",
            borderLeft: isEventsPanelOpen
              ? `1px solid ${theme.palette.divider}`
              : "none",
            height: "100%",
          }}
        >
          {isEventsPanelOpen && (
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
                px: 2,
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: theme.palette.grey[100],
                },
                "&::-webkit-scrollbar-thumb": {
                  background: theme.palette.grey[400],
                  borderRadius: "4px",
                },
              }}
            >
              {getVisibleEvents().map((event, index) => {
                const sentimentValue = event.sentiment || 0;
                const intensity = Math.min(Math.abs(sentimentValue) / 10, 1);
                const backgroundColor = sentimentValue > 0
                  ? `rgba(0, 255, 0, ${intensity * 0.15})`
                  : `rgba(255, 0, 0, ${intensity * 0.15})`;
                const chatName = event.hash ? chatNames[event.hash] : 'Unknown Chat';

                return (
                  <Box
                    key={index}
                    sx={{
                      mb: 1.5,
                      p: 1.5,
                      borderRadius: 2,
                      backgroundColor,
                      boxShadow: 1,
                      border: `1px solid ${theme.palette.divider}`,
                      transition: "transform 0.4s ease",
                      "&:hover": {
                        transform: "translateX(5px)",
                      },
                    }}
                  >
                    {/* Top Row: Date and Sentiment */}
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 0.25
                    }}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme.palette.text.secondary,
                        }}
                      >
                        {format(new Date(event.timestamp_range.start), "MMMM d, yyyy")}
                      </Typography>

                      <Typography
                        sx={{
                          color: sentimentValue > 0 ? 'success.main' : 'error.main',
                          fontWeight: 'bold',
                          fontSize: '1rem',
                        }}
                      >
                        {sentimentValue > 0 ? '+' : ''}{sentimentValue.toFixed(2)}
                      </Typography>
                    </Box>

                    {/* Event Title */}
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: "bold",
                        color: theme.palette.text.primary,
                        mb: 0.25
                      }}
                    >
                      {event.event}
                    </Typography>

                    {/* Chat Name */}
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.text.secondary,
                        display: 'block',
                        mb: 0.5
                      }}
                    >
                      {chatName}
                    </Typography>

                    {/* Description */}
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.875rem",
                        lineHeight: 1.5,
                      }}
                    >
                      {event.event_deep_dive.event_summary}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default GlobalSentimentChart;
