import { OpenAI } from "openai";
import { z } from "zod";
import { config } from "../config";
import { ChatMessage } from "./types";
import { checkFileExists, uploadJsonToS3 } from "./s3Storage";
import { requestFileWithToken } from "./s3cache";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

// Define types
export interface Chapter {
  title: string;
  summary: string;
}

export interface MissingEvent {
  eventPrompt: string;
  userInput?: string;
}

// Story styles - single source of truth
export const STORY_STYLES = [
  {
    name: "Fantasy",
    description:
      "A magical world where your story unfolds with elements of wonder and mysticism",
    example: "Think Lord of the Rings or Harry Potter style narrative",
  },
  {
    name: "Sci-Fi",
    description:
      "Your story reimagined in a futuristic setting with advanced technology",
    example: "Similar to Star Wars or Dune",
  },
  {
    name: "Romance",
    description:
      "A heartfelt journey focusing on relationships and emotional connections",
    example: "In the style of Nicholas Sparks or Jane Austen",
  },
  {
    name: "Mystery",
    description: "Your story told through the lens of intrigue and suspense",
    example: "Like Sherlock Holmes or Agatha Christie",
  },
  {
    name: "Shakespearean",
    description: "A shakespearean play full of drama and twists",
    example: "Like Romeo and Juliet",
  },
];

// Define schema for story response
const StoryResponseSchema = z.object({
  chapters: z.array(
    z.object({
      title: z.string(),
      summary: z.string(),
    })
  ),
});

// Core story generation logic - no tokens needed
export async function generateChapters(
  style: string,
  chatData: ChatMessage[]
): Promise<Chapter[]> {
  try {
    console.log("Starting generateChapters function...", {
      style,
      chatDataLength: chatData?.length
    });
    
    const chatText = chatData
      .map((msg) => `${msg.user}: ${msg.message}`)
      .join("\n")
      .slice(-70000);

    console.log("Prepared chat text for OpenAI:", {
      chatTextLength: chatText.length,
      firstFewChars: chatText.slice(0, 100)
    });

    const response = await openai.chat.completions.create({
      model: config.textModel,
      messages: [
        {
          role: "system",
          content: `You are a creative writing assistant that transforms chat conversations into fun story chapters. Create engaging chapter titles and summaries in the requested style. Try to find four distinct "arcs" in the chats that are easily convertable to a story and create a chapter for each arc.`,
        },
        {
          role: "user",
          content: `Here is the chat conversation:
${chatText}

Please create 4 fun chapter titles and summaries in ${style} style based on this chat conversation. Make it feel like a complete story arc. VERY IMPORTANT!! THE CHAPTERS AND STORIES SHOULD BE DIRECTLY INSPIRED BY THE CHAT AND THE EVENTS THAT TAKE PLACE IN IT!!

Respond in this exact JSON format:
{
  "chapters": [
    {
      "title": "An engaging ${style} style chapter title",
      "summary": "A brief, fun summary of what this chapter would be about"
    }
  ]
}`,
        },
      ],
      response_format: { type: "json_object" },
      temperature: 1,
    });

    console.log("Received OpenAI response:", {
      hasContent: !!response.choices[0]?.message.content,
      responseContent: response.choices[0]?.message.content
    });

    const content = response.choices[0]?.message.content;
    if (!content) {
      throw new Error("No content in response");
    }

    const parsed = JSON.parse(content);
    console.log("Parsed response:", parsed);

    if (!StoryResponseSchema.safeParse(parsed).success) {
      throw new Error("Response failed schema validation");
    }

    return parsed.chapters;
  } catch (error) {
    console.error("Error in generateChapters:", error);
    throw error;
  }
}

export async function generateChapterContent(
  style: string,
  chapterIndex: number,
  chatData: ChatMessage[]
): Promise<string> {
  try {
    console.log("Generating chapter content...");

    const chatText = chatData
      .map((msg) => `${msg.user}: ${msg.message}`)
      .join("\n")
      .slice(-70000);

    const response = await openai.chat.completions.create({
      model: config.textModel,
      messages: [
        {
          role: "system",
          content: `You are a creative writing assistant that transforms chat conversations into engaging stories.`,
        },
        {
          role: "user",
          content: `Here is the chat conversation to transform:
${chatText}

Please write chapter ${chapterIndex + 1} in ${style} style based on this chat conversation.`,
        },
      ],
    });

    return response.choices[0].message.content || "Failed to generate content.";
  } catch (error) {
    console.error("Error generating chapter content:", error);
    throw error;
  }
}

// S3 operations that need tokens
export const storyS3Operations = {
  loadStory: async (
    path: string,
    hash: string,
    token: string,
    refreshToken: () => Promise<string>
  ) => {
    const desiredPath = path.replace(":hash:", hash);
    const exists = await checkFileExists(desiredPath);
    if (!exists) {
      return null;
    }
    return await requestFileWithToken(token, refreshToken, desiredPath);
  },

  saveStory: async (
    path: string,
    hash: string,
    result: any
  ) => {
    const desiredPath = path.replace(":hash:", hash);
    await uploadJsonToS3(desiredPath, result);
  }
};
