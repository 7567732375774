import AndroidIcon from "@mui/icons-material/Android";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MessageIcon from "@mui/icons-material/Message";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { FileIcon } from "lucide-react";
import React, { useState } from "react";
import { keyframes } from '@mui/material/styles';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  // border: "1px solid #e0e0e0",
  border: "2px solid #B0B0B0",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "none",
  background: "transparent",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: `${theme.spacing(1)} 0`,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(1.5, 2.5),
  background: "transparent",
  "&.Mui-expanded": {
    minHeight: 48,
  },
  "& .MuiAccordionSummary-content": {
    margin: "0",
    "&.Mui-expanded": {
      margin: "12px 0",
    },
  },
}));

const AccordionWrapper = styled(Box)({
  position: "relative",
});

const fileTypes = [
  { name: "WhatsApp (Mobile)", icon: WhatsAppIcon },
  { name: "WhatsApp (Laptop)", icon: WhatsAppIcon },
  { name: "iMessage (Mac)", icon: MessageIcon },
  { name: "Instagram", icon: InstagramIcon },
  { name: "Facebook", icon: FacebookIcon },
  { name: "Telegram (Laptop)", icon: TelegramIcon },
  { name: "Android", icon: AndroidIcon },
];

const instructions = [
  {
    title: "How do I export my WhatsApp chat from my phone (easiest)",
    steps: [
      "Go to Settings ⚙️",
      'Select "Chats" 💬',
      'Click on "Export chat"',
      "Select the chat you wish to export",
      'Click on "Without Media", and choose how you wish to export to your laptop',
      "Switch to your laptop and click the Upload File button above (you can also drag and drop files onto the button from mail/messages) and select your file!",
    ],
  },
  {
    title: "How do I export my WhatsApp chat (Others)?",
    steps: [
      "Open the chat you wish to export",
      "Tap More options > More > Export chat",
      "Tap without media and save to downloads. For mobile devices you can message, email, or airdrop the file to yourself.",
      "Click the Upload File button above (can also drag and drop files into the button from mail/messages) and select your file!",
    ],
  },
  {
    title: "How do I export my iMessage chat (Mac)?",
    steps: [
      "READ THE WHOLE THING BEFORE UPLOADING CHAT",
      "Copy this file path: ~/Library/Messages and click on the upload file button.",
      "Hold Command + Shift + G on your keyboard, and paste ~/Library/Messages into the search bar",
      "Select the first option that comes up { > Library > Messages }",
      "Select the chat.db file and wait for the pop up menu (this can take a while depending on how much you use imessage, sometimes over a minute)",
      "Finally, in the chat pop up menu, select the chat you wish to analyze",
      "Please note that the file includes your entire imessage history, it is once the file is finished processing that you can select your individual chats",
    ],
  },
  {
    title: "How do I export my Facebook chat?",
    steps: [
      "Open the Messenger app and go to Settings ⚙️",
      "Scroll down and select See more in Accounts Center",
      'Select "Your information and permissions", then Download Your Information, pressing the Download or transfer information button, choosing your account, and selecting Some of your Information',
      'Search Messages, select Download to Device and select the chat history you wish to export. By default, you get one year of messages, but you can select "All Time" for completeness.',
      'VERY IMPORTANT: Click on "Format", and select "JSON", and "Low Quality". Once ready, input your email and receive your file (in a few hours)',
      "Mow the lawn, watch a series and wait for that email notification!",
      "Download the file from your instagram/facebook app and transfer it to your computer (email, messages, airdrop etc.)",
      "Click the Upload File button and select your file, or drag and drop it above!",
    ],
  },
  {
    title: "How do I export my Instagram chat?",
    steps: [
      "Open the Instagram app and click on your profile, then navigate to Settings and Activity (the three bars in the top right of your profile page)",
      "Select Your Activity",
      "Scroll all the way down and click Download Your Information, pressing the Download or transfer information button, choosing your account, and selecting Some of your Information",
      'Search Messages, select Download to Device and select the chat history you wish to export. By default, you get one year of messages, but you can select "All Time" for completeness.',
      'VERY IMPORTANT: Click on "Format", and select "JSON", and "Low Quality". Once ready, input your email and receive your file (in a few hours)',
      "Mow the lawn, watch a series and wait for that email notification!",
      "Download the file from your instagram/facebook app and transfer it to your computer (email, messages, airdrop etc.)",
      "Click the Upload File button and select your file, or drag and drop it above!",
    ],
  },
  {
    title: "How do I export my Android chat?",
    steps: [
      "https://www.wikihow.com/Copy-an-Entire-Text-Conversation-on-Android",
    ],
  },
  {
    title: "How do I export my Telegram chat (Laptop)?",
    steps: [
      "Open Telegram Desktop on your computer.",
      "Click on the three dots in the top-right corner to open the menu.",
      "Select 'export chat history' from the menu.",
      "De-select all checkable items",
      "Select the 'Machine-readable JSON' format next to 'Format'.",
      "Click 'Export'",
      "Once the export is complete navigate back to Mosaic, click the Upload File button above and select your exported file.",
    ],
  },
];

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
`;

const PulsingAccordion = styled(StyledAccordion)(({ theme }) => ({
  animation: `${pulse} 2s infinite`,
  '&:hover': {
    animation: 'none',
  },
}));

const SampleFileWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'move',
}));

interface FileUploadInstructionsLandingProps {
  onFileTypeSelect: (fileType: string) => void;
  onTryItOutClick: () => void;
  sampleFileVisible: boolean;
  onSampleFileDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
}

const FileUploadInstructionsLanding: React.FC<
  FileUploadInstructionsLandingProps
> = ({ 
  onFileTypeSelect, 
  onTryItOutClick,
  sampleFileVisible,
  onSampleFileDragStart
}) => {
  const [expandedFileType, setExpandedFileType] = useState<string | null>(null);

  const handleFileTypeClick = (fileType: string) => {
    setExpandedFileType(expandedFileType === fileType ? null : fileType);
    onFileTypeSelect(fileType);
  };

  const getInstructions = (fileType: string) => {
    if (fileType === "WhatsApp (Mobile)") {
      return instructions[0];
    } else if (fileType === "WhatsApp (Laptop)") {
      return instructions[1];
    } else if (fileType === "Telegram (Laptop)") {
      return instructions.find(instruction => instruction.title.includes("Telegram"));
    } else {
      return instructions.find((instruction) =>
        instruction.title
          .toLowerCase()
          .includes(fileType.toLowerCase().replace(" (mac)", ""))
      );
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        From which chat application do you want to export your chat?
      </Typography>
      {fileTypes.map((fileType, index) => (
        <AccordionWrapper key={fileType.name}>
          <StyledAccordion
            expanded={expandedFileType === fileType.name}
            onChange={() => handleFileTypeClick(fileType.name)}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${fileType.name}-content`}
              id={`${fileType.name}-header`}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <fileType.icon sx={{ marginRight: 1 }} />
                <Typography>{fileType.name}</Typography>
              </Box>
            </StyledAccordionSummary>
            <AccordionDetails>
              {getInstructions(fileType.name) ? (
                <List>
                  {getInstructions(fileType.name)!.steps.map(
                    (step, stepIndex) => (
                      <React.Fragment key={stepIndex}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                <strong>Step {stepIndex + 1}:</strong> {step}
                              </Typography>
                            }
                          />
                        </ListItem>
                        {stepIndex <
                          getInstructions(fileType.name)!.steps.length - 1 && (
                          <Divider variant="middle" component="li" />
                        )}
                      </React.Fragment>
                    )
                  )}
                </List>
              ) : (
                <Typography>
                  Instructions for {fileType.name} are coming soon...
                </Typography>
              )}
            </AccordionDetails>
          </StyledAccordion>
        </AccordionWrapper>
      ))}
      <AccordionWrapper>
        <PulsingAccordion
          expanded={sampleFileVisible}
          onChange={onTryItOutClick}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="try-it-out-content"
            id="try-it-out-header"
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FileIcon style={{ marginRight: "8px" }} />
              <Typography>
                {sampleFileVisible ? "Reset" : "TRY IT OUT"}
              </Typography>
            </Box>
          </StyledAccordionSummary>
        </PulsingAccordion>
        {sampleFileVisible && (
          <SampleFileWrapper draggable onDragStart={onSampleFileDragStart}>
            <FileIcon size={24} style={{ marginBottom: "8px" }} />
            <Typography variant="body2">SampleChat.txt</Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mt: 1, textAlign: "center" }}
            >
              Drag this file to the upload area
            </Typography>
          </SampleFileWrapper>
        )}
      </AccordionWrapper>
    </Box>
  );
};

export default FileUploadInstructionsLanding;
