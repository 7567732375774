import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import { keyframes } from '@mui/material/styles'
import { UploadIcon } from 'lucide-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  withFileUploadAndParse,
  WithFileUploadAndParseProps,
} from '../FileUploadAndParse'
import SampleChatFile from '../SampleChatFile'
import FileUploadInstructionsLanding from './FileUploadInstructionsLanding'
import RightSideContent from './RightSideContent'

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`
const animateCloud = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(calc(100vw + 100%)); }
`

const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: '160vh', // Increased from 140vh to 200vh
  width: '100vw',
  overflow: 'auto',
  backgroundImage: 'linear-gradient(45deg, #e6f7ff, #b3e0ff, #99d6ff, #e6f7ff)',
  backgroundSize: '400% 400%',
  animation: `${gradientAnimation} 15s ease infinite`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0 5%',
  position: 'relative',
  zIndex: 0,

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '256px',
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><defs><linearGradient id="bottomGradient" x1="0%" y1="100%" x2="0%" y2="0%"><stop offset="0%" stop-color="%234facfe" stop-opacity="0.7" /><stop offset="20%" stop-color="%234facfe" stop-opacity="0.5" /><stop offset="40%" stop-color="%2300f2fe" stop-opacity="0.3" /><stop offset="60%" stop-color="%2300f2fe" stop-opacity="0.2" /><stop offset="80%" stop-color="%2300f2fe" stop-opacity="0.1" /><stop offset="100%" stop-color="%2300f2fe" stop-opacity="0" /></linearGradient></defs><path fill="url(%23bottomGradient)" d="M0 50 Q 25 45, 50 50 T 100 50 V100 H0"><animate attributeName="d" dur="10s" repeatCount="indefinite" values="M0 50 Q 25 45, 50 50 T 100 50 V100 H0; M0 50 Q 25 55, 50 50 T 100 50 V100 H0; M0 50 Q 25 45, 50 50 T 100 50 V100 H0"/></path></svg>')`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    zIndex: 1, // Set z-index for the gradient
  },

  '& > .cloud': {
    position: 'absolute',
    background: '#fff',
    backgroundImage: 'linear-gradient(top, #fff 5%, #f1f1f1 100%)',
    borderRadius: '100px',
    boxShadow: '0 8px 5px rgba(0, 0, 0, 0.1)',
    height: '120px',
    width: '350px',
    opacity: 0.8,
    zIndex: 2, // Set z-index for clouds

    '&:after, &:before': {
      content: '""',
      position: 'absolute',
      background: '#fff',
      zIndex: -1,
    },

    '&:after': {
      borderRadius: '100px',
      height: '100px',
      left: '50px',
      top: '-50px',
      width: '100px',
    },

    '&:before': {
      borderRadius: '200px',
      width: '180px',
      height: '180px',
      right: '50px',
      top: '-90px',
    },
  },

  '& > .x1': {
    animation: `${animateCloud} 60s linear infinite`,
    transform: 'scale(0.65)',
    top: '10%',
    left: '-20%',
  },

  '& > .x2': {
    animation: `${animateCloud} 80s linear infinite`,
    transform: 'scale(0.4)',
    top: '25%',
    left: '-15%',
    animationDelay: '-20s',
  },

  '& > .x3': {
    animation: `${animateCloud} 100s linear infinite`,
    transform: 'scale(0.5)',
    top: '40%',
    left: '-30%',
    animationDelay: '-40s',
  },

  '& > .x4': {
    animation: `${animateCloud} 90s linear infinite`,
    transform: 'scale(0.7)',
    top: '60%',
    left: '-25%',
    animationDelay: '-15s',
  },

  '& > .x5': {
    animation: `${animateCloud} 120s linear infinite`,
    transform: 'scale(0.55)',
    top: '80%',
    left: '-10%',
    animationDelay: '-70s',
  },
}))

const TitleWrapper = styled(Box)({
  position: 'relative',
  display: 'inline-block', // Add this to contain the pseudo-element
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '120%',
    height: '120%',
    background: 'rgba(255, 255, 255, 0.7)',
    filter: 'blur(30px)',
    zIndex: 1,
  },
})

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '7rem',
  fontWeight: 'bold',
  fontFamily: "'Comfortaa', sans-serif",
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, #00C4FF, #60A5FA)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  lineHeight: 1.1,
  marginBottom: theme.spacing(1), // Decreased from theme.spacing(2)
  position: 'relative',
  zIndex: 2,
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 400,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1), // Changed from theme.spacing(4)
  position: 'relative',
  zIndex: 2,
}))

const DropZone = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '300px',
  width: '100%',
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'transparent',
  transition: theme.transitions.create(['border', 'background-color'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(4),
}))

const NonAuthenticatedUploadComponent: React.FC<
  WithFileUploadAndParseProps
> = ({ onFileUpload, isLoading, error, fileInputRef }) => {
  const theme = useTheme()
  const [isDragOver, setIsDragOver] = useState(false)

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragOver(false)
    const data = event.dataTransfer.getData('application/json')
    if (data) {
      try {
        const parsedData = JSON.parse(data)
        if (parsedData.isSampleFile && parsedData.file) {
          const sampleFile = new File([parsedData.file], 'SampleChat.txt', {
            type: 'text/plain',
          })
          onFileUpload(sampleFile)
          return
        }
      } catch (error) {
        console.error('Error parsing drag data:', error)
      }
    }
    const files = event.dataTransfer.files
    if (files && files.length > 0) {
      onFileUpload(files[0])
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragOver(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragOver(false)
  }

  const handleButtonClick = () => {
    console.log('handleButtonClick')
    fileInputRef.current?.click()
  }

  return (
    <DropZone
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={{
        backgroundColor: isDragOver
          ? theme.palette.action.hover
          : 'transparent',
      }}
    >
      <Typography variant="h6" color="text.primary" sx={{ mb: 2 }}>
        Chat Upload Area
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      {isLoading ? (
        <CircularProgress sx={{ mb: 2 }} />
      ) : (
        <Button
          onClick={handleButtonClick}
          startIcon={<UploadIcon />}
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
        >
          Select File
        </Button>
      )}
      <Typography variant="body2" color="text.secondary" align="center">
        {isLoading
          ? 'Processing your file...'
          : 'Drag and drop a file anywhere in this area,\nor click the button to select a file\n(Supports .txt, .zip, .json, and .db files)'}
      </Typography>
      <input
        ref={fileInputRef}
        type="file"
        accept=".txt,.zip,.json,.db"
        style={{ display: 'none' }}
        onChange={(e) => {
          console.log('onChange', e.target.files)
          e.target.files && onFileUpload(e.target.files[0])
        }}
      />
    </DropZone>
  )
}

const EnhancedUploadComponent = withFileUploadAndParse(
  NonAuthenticatedUploadComponent
)

const Header = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(2),
  zIndex: 1000, // Increased z-index to be above all other elements
}))

const AuthButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  padding: '10px 20px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  // borderRadius: "50px",
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease',
  zIndex: 1001, // Slightly higher than the Header
}))

const SignInButton = styled(AuthButton)({
  color: '#000',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  border: '2px solid #000',
  '&:hover': {
    backgroundColor: '#000',
    color: '#fff',
  },
})

const SignUpButton = styled(AuthButton)({
  color: '#fff',
  backgroundColor: '#000',
  border: '2px solid #000',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
})

// Update the TitleContainer
const TitleContainer = styled(Box)({
  position: 'absolute',
  top: '2%',
  left: '50%',
  transform: 'translateX(-50%)',
  textAlign: 'center',
  zIndex: 10,
  width: '100%',
})

const ContentContainer = styled(Box)({
  display: 'flex',
  height: 'calc(100vh - 150px)',
  width: '100%',
  marginTop: '320px', // Increased from 300px to create more space below the description
  position: 'relative',
  zIndex: 4,
})

const UploadSection = styled(Box)({
  position: 'absolute',
  bottom: '-200px', // Adjusted from "-300px" to "-200px"
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
  maxWidth: '1200px',
  zIndex: 6,
})

const LeftSection = styled(Box)({
  flex: '0 0 40%',
  padding: '20px',
  overflowY: 'auto',
  height: 'calc(100% - 100px)', // Adjust this value as needed
  display: 'flex',
  flexDirection: 'column',
})

const RightSection = styled(Box)({
  flex: '0 0 60%', // Changed from "0 0 60%"
  padding: '20px',
  position: 'relative',
  zIndex: 5,
  height: '80%', // Added to reduce height
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '40px',
  paddingLeft: '40px',
  marginTop: '-30px', // Added to move the section up
})

const StyledFileUploadInstructions = styled(Box)({
  position: 'relative',
  zIndex: 3, // Higher than clouds
})

const Description = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  textAlign: 'center',
  marginTop: theme.spacing(1), // Decreased from theme.spacing(2)
  marginBottom: theme.spacing(6), // Increased from theme.spacing(4)
  position: 'relative',
  zIndex: 2,
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  lineHeight: 1.3,
}))

const MosaicLandingPage: React.FC = () => {
  const [selectedFileType, setSelectedFileType] = useState<string | null>(null)
  const [sampleFileVisible, setSampleFileVisible] = useState(false)
  const navigate = useNavigate()

  const handleFileTypeSelect = (fileType: string) => {
    setSelectedFileType(fileType)
  }

  const handleTryItOutClick = () => {
    setSampleFileVisible(!sampleFileVisible)
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const sampleFile = SampleChatFile()
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({
        isSampleFile: true,
        file: sampleFile,
      })
    )
    e.dataTransfer.effectAllowed = 'copy'
  }

  const handleUploadSuccess = (chatId: string) => {
    console.log('Chat uploaded successfully:', chatId)
  }

  const handleSignIn = () => {
    navigate('/signin')
  }

  const handleSignUp = () => {
    navigate('/signup')
  }

  return (
    <PageContainer>
      <Header>
        <SignInButton onClick={handleSignIn}>Sign In</SignInButton>
        <SignUpButton onClick={handleSignUp}>Sign Up</SignUpButton>
      </Header>

      {/* Clouds */}
      <div className="cloud x1"></div>
      <div className="cloud x2"></div>
      <div className="cloud x3"></div>
      <div className="cloud x4"></div>
      <div className="cloud x5"></div>

      <TitleContainer>
        <Subtitle>Chat Insights by</Subtitle>
        <TitleWrapper>
          <Title>Mosaic</Title>
        </TitleWrapper>
        <Description>
          Drag and drop your chat file in the upload area below to view
          analytics
        </Description>
      </TitleContainer>

      <ContentContainer>
        <LeftSection>
          <StyledFileUploadInstructions>
            <FileUploadInstructionsLanding
              onFileTypeSelect={handleFileTypeSelect}
              onTryItOutClick={handleTryItOutClick}
              sampleFileVisible={sampleFileVisible}
              onSampleFileDragStart={handleDragStart}
            />
          </StyledFileUploadInstructions>
        </LeftSection>
        <RightSection>
          <RightSideContent selectedFileType={selectedFileType} />
        </RightSection>
        <UploadSection>
          <EnhancedUploadComponent onUploadSuccess={handleUploadSuccess} />
        </UploadSection>
      </ContentContainer>
    </PageContainer>
  )
}

export default MosaicLandingPage
