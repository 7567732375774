import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { LogOut as LogOutIcon, Menu as MenuIcon } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import {
  getPreferredUsername,
  updatePreferredUsername,
} from '../../utils/cognitoUpdater'
import { PersonalityTheme } from './components/personalityThemes/types'

interface HeaderProps {
  user: any
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void
  handleSignOut: () => void
  personalityTheme: PersonalityTheme
}

const Header: React.FC<HeaderProps> = ({
  user,
  toggleDrawer,
  handleSignOut,
  personalityTheme,
}) => {
  const theme = useTheme()
  const [preferredUsername, setPreferredUsername] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [newUsername, setNewUsername] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchPreferredUsername = async () => {
      const username = await getPreferredUsername()
      setPreferredUsername(username || '')

      const isGoogleUsername = /^google_\d+$/.test(user?.payload?.username)
      if (isGoogleUsername && !username) {
        setOpenModal(true)
      }
      setIsLoading(false)
    }
    fetchPreferredUsername()
  }, [user?.payload?.username])

  const isGoogleUsername = /^google_\d+$/.test(user?.payload?.username)

  const handleUpdateUsername = async () => {
    if (newUsername) {
      await updatePreferredUsername(newUsername)
      setPreferredUsername(newUsername)
      setOpenModal(false)
    }
  }

  const handleUsernameClick = () => {
    setOpenModal(true);
  };

  if (isLoading || (isGoogleUsername && !preferredUsername)) {
    return (
      <>
        <Box sx={{ height: '64px' }} />
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="username-modal"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
          disableEscapeKeyDown
          disableAutoFocus
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: 400,
              zIndex: 1300,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Choose Your Username
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Please choose a preferred username for your account
            </Typography>
            <TextField
              fullWidth
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              label="Preferred Username"
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={handleUpdateUsername}
              disabled={!newUsername}
            >
              Save Username
            </Button>
          </Box>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          backgroundColor: 'white',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          filter: openModal ? 'blur(4px)' : 'none',
          pointerEvents: openModal ? 'none' : 'auto',
        }}
      >
        <IconButton
          onClick={toggleDrawer(true)}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h4"
          component="h1"
          onClick={handleUsernameClick}
          sx={{
            fontWeight: 'bold',
            background: personalityTheme.typography.titleGradient,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontFamily: "'Comfortaa', sans-serif", // Always use Comfortaa
            flexGrow: 1,
            textAlign: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          {preferredUsername || user?.payload?.username}'s HomeBoard
        </Typography>
        <Button
          variant="outlined"
          onClick={handleSignOut}
          startIcon={<LogOutIcon />}
          sx={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          }}
        >
          Sign Out
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="username-modal"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        disableEscapeKeyDown
        disableAutoFocus
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: 400,
            zIndex: 1300,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Choose Your Username
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Please choose a preferred username for your account
          </Typography>
          <TextField
            fullWidth
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            label="Preferred Username"
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleUpdateUsername}
            disabled={!newUsername}
          >
            Save Username
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default Header
